import { Component, forwardRef, useMemo } from "react";
import {
  FlexStyle,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native";
import { SvgProps } from "react-native-svg";
import {
  ActivityIndicator,
  RectButton,
  RectButtonProps,
  Text,
  View,
  ViewProps,
} from "./Themed";
import { RectButton as _RectButton } from "react-native-gesture-handler";
import * as Haptics from "expo-haptics";
import { useColors } from "./Themed/useColors";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { LinearGradient } from "expo-linear-gradient";
import { Link } from "expo-router";
import { ExpoRouter } from ".expo/types/router";

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);
const AnimatedRectButton = Animated.createAnimatedComponent(RectButton);

export interface ButtonProps extends RectButtonProps {
  key?: string | number;
  type?:
    | "flat"
    | "primary"
    | "secondary"
    | "destructive"
    | "primary-destructive"
    | "semitransparent";
  justifyContent?: FlexStyle["justifyContent"];
  Icon?: React.FC<SvgProps>;
  iconSize?: number;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  loading?: boolean;
  borderColor?: string;
  numberOfLines?: number;
  textColor?: string;
  blinking?: boolean;
  bold?: boolean;
  dataSet?: { [key: string]: string | boolean };
}

interface ButtonsProps extends ViewProps {
  buttons: ButtonPropsType[];
  buttonStyle?: StyleProp<ViewStyle>;
}

export const Buttons = function ({
  buttons,
  style,
  buttonStyle,
  ...rest
}: ButtonsProps) {
  return (
    <View style={style} {...rest}>
      {buttons.map((_, index) => (
        <Button key={index} style={[styles.buttonsItem, buttonStyle]} {..._} />
      ))}
    </View>
  );
};

type ButtonPropsType = Omit<Partial<ExpoRouter.LinkProps>, "style"> &
  ButtonProps;
type ButtonType = (props: ButtonPropsType) => JSX.Element;

export const Button = forwardRef<_RectButton, ButtonPropsType>(function Button(
  {
    style,
    children,
    type = "primary",
    justifyContent = "center",
    Icon,
    iconSize,
    text,
    textStyle,
    disabled,
    loading,
    borderColor,
    numberOfLines,
    textColor,
    blinking,
    dataSet,
    onPress,
    ...rest
  },
  ref
) {
  const c = useColors();

  const colors = useMemo(() => {
    if (type === "primary")
      return {
        background: c.primary,
        text: textColor || "white",
      };

    if (type === "secondary")
      return {
        border: c.primary,
        text: textColor || c.primary,
      };

    if (type === "destructive")
      return {
        background: "#FE564B20",
        text: textColor || "#FE564B",
      };

    if (type === "primary-destructive")
      return {
        background: "#FE564B",
        text: textColor || "white",
      };

    if (type === "semitransparent")
      return {
        background: c.primary + "20",
        text: textColor || c.primary,
      };

    return {
      background: "transparent",
      text: textColor || c.primary,
    };
  }, [c.primary, textColor, type]);

  const y = useSharedValue(0);
  const gradientWidth = useSharedValue(-1);

  const gradientStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: -gradientWidth.value * (1 - y.value) }],
    };
  });

  const component = (
    <AnimatedRectButton
      ref={ref}
      style={StyleSheet.flatten([
        styles.container,
        { opacity: disabled ? (loading ? 0.9 : 0.3) : 1, justifyContent },
        style,
      ])}
      color={colors.background}
      enabled={!disabled && !loading}
      underlayColor={colors.text}
      rippleColor={colors.text}
      onActiveStateChange={
        Platform.OS === "ios"
          ? () => {
              Haptics.selectionAsync();
            }
          : undefined
      }
      onPress={onPress}
      // @ts-expect-error for web css
      dataSet={{ disabled: disabled || loading, ...dataSet }}
      {...rest}
    >
      {blinking && Platform.OS !== "web" && (
        <View style={styles.buttonGradient}>
          <AnimatedLinearGradient
            style={[styles.buttonGradient, gradientStyle]}
            colors={[c.primary + "00", "#fff8", c.primary + "00"]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            onLayout={(e) => {
              gradientWidth.value = e.nativeEvent.layout.width;
              y.value = withRepeat(
                withDelay(
                  3000,
                  withTiming(2, { duration: 700, easing: Easing.linear })
                ),
                Infinity
              );
            }}
          />
        </View>
      )}
      {(colors.border || borderColor) && (
        <View
          style={[styles.border, { borderColor: borderColor || colors.border }]}
        />
      )}
      {Icon && (
        <Icon
          style={[!!text && { marginEnd: 6 }, loading && { opacity: 0 }]}
          color={colors.text}
          {...(iconSize && { width: iconSize, height: iconSize })}
        />
      )}
      {text && (
        <Text
          style={[
            styles.text,
            rest.bold && { fontWeight: "600" },
            textStyle,
            loading && { opacity: 0 },
          ]}
          color={colors.text}
          numberOfLines={numberOfLines}
        >
          {text}
        </Text>
      )}
      {children}
      {loading && (
        <ActivityIndicator
          style={StyleSheet.absoluteFill}
          color={colors.text}
        />
      )}
    </AnimatedRectButton>
  );

  if (rest.href)
    return (
      <Link asChild href={rest.href} {...rest}>
        {component}
      </Link>
    );

  return component;
}) as never as ButtonType;

interface HeaderButtonsProps extends ViewProps {
  buttons: ButtonPropsType[];
}

export const HeaderButtons = function ({
  style,
  buttons,
  ...rest
}: HeaderButtonsProps) {
  return (
    <View style={[styles.headerButtons, style]} {...rest}>
      {buttons.map((props, index) => (
        <_HeaderButton key={index} {...props} />
      ))}
    </View>
  );
};

export const _HeaderButton: ButtonType = function (props) {
  const { style, ...rest } = { ...props };

  return (
    <Button
      style={[
        styles.headerButtonContainer,
        !!rest.text && {
          width: "auto",
          paddingHorizontal: 8,
          marginHorizontal: -8,
          borderRadius: 6,
        },
        style,
      ]}
      hitSlop={{ left: 32, top: 32, bottom: 32, right: 32 }}
      {...rest}
    />
  );
};

export class HeaderButton extends Component<ButtonPropsType> {
  render() {
    return <HeaderButtons buttons={[{ type: "flat", ...this.props }]} />;
  }
}

const styles = StyleSheet.create({
  container: {
    height: 44,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 12,
    overflow: "hidden",
  },
  border: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 12,
    borderWidth: 1,
  },
  text: {
    fontSize: 16,
    fontWeight: "500",
    textAlign: "center",
  },

  headerButtons: {
    flexDirection: "row",
    marginHorizontal: -2,
    paddingHorizontal: Platform.select({ web: 16, default: 0 }),
    gap: 16,
  },
  headerButtonContainer: {
    width: 34,
    height: 34,
    paddingHorizontal: 0,
    marginHorizontal: 2,
  },
  buttonsItem: {
    marginVertical: 5,
  },

  buttonGradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -1,
    borderRadius: 12,
    overflow: "hidden",
  },
});
