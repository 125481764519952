import { StackModal, modalCancelOptions } from "components/common";
import { Stack } from "expo-router";
import { useTranslation } from "react-i18next";

export default function AppLayout() {
  const { t } = useTranslation("pages");

  return (
    <StackModal>
      {/* campaign */}
      <Stack.Screen
        name="campaign/create"
        options={{ title: t("campaign.create.title") }}
      />
      <Stack.Screen
        name="campaign/[id]/index"
        options={{ title: t("campaign.title") }}
      />
      <Stack.Screen
        name="campaign/[id]/edit"
        options={{
          ...modalCancelOptions,
          animation: "fade",
          title: t("campaign.edit.title"),
        }}
      />
      <Stack.Screen
        name="campaign/[id]/ads/index"
        options={{ title: t("campaign.ads.title") }}
      />
      <Stack.Screen
        name="campaign/picker/[id]"
        options={{ title: t("campaign.picker.title") }}
      />

      {/* ad  */}
      <Stack.Screen name="ad/[id]/index" options={{ title: t("ad.title") }} />
      <Stack.Screen
        name="ad/create"
        options={{ title: t("ad.create.title") }}
      />

      {/* car  */}
      <Stack.Screen
        name="car/create"
        options={{ title: t("car.create.title") }}
      />
      <Stack.Screen name="car/[id]/index" options={{ title: t("car.title") }} />
      <Stack.Screen
        name="car/[id]/edit"
        options={{
          ...modalCancelOptions,
          animation: "fade",
          title: t("car.edit.title"),
        }}
      />
      <Stack.Screen
        name="car/[id]/driver/add"
        options={{ title: t("car.driver.add.title") }}
      />
      <Stack.Screen
        name="car/picker/[id]"
        options={{ title: t("car.picker.title") }}
      />

      {/* org  */}
      <Stack.Screen name="org/add" options={{ title: t("org.add.title") }} />
      <Stack.Screen name="org/[id]/index" options={{ title: t("org.title") }} />
      <Stack.Screen
        name="org/[id]/settings"
        options={{
          ...modalCancelOptions,
          animation: "fade",
          title: t("org.settings.title"),
        }}
      />
      <Stack.Screen
        name="org/picker/[id]"
        options={{ title: t("org.picker.title") }}
      />

      {/* orgMember  */}
      <Stack.Screen
        name="orgMember/invite"
        options={{ title: t("orgMember.invite.title") }}
      />
      <Stack.Screen
        name="orgMember/[id]/index"
        options={{ title: t("orgMember.title") }}
      />
      <Stack.Screen
        name="orgMember/[id]/edit"
        options={{
          ...modalCancelOptions,
          animation: "fade",
          title: t("orgMember.edit.title"),
        }}
      />
      <Stack.Screen
        name="orgMember/picker/[id]"
        options={{ title: t("orgMember.picker.title") }}
      />

      {/* user  */}
      <Stack.Screen
        name="user/[id]/index"
        options={{ title: t("user.title") }}
      />
      <Stack.Screen
        name="user/invite"
        options={{ title: t("user.invite.title") }}
      />

      {/* device  */}
      <Stack.Screen
        name="device/[id]/index"
        options={{ title: t("device.title") }}
      />

      {/* app  */}
      <Stack.Screen name="app/[id]/index" options={{ title: t("app.title") }} />
      <Stack.Screen
        name="app/create"
        options={{ title: t("app.create.title") }}
      />

      {/* mediaAd  */}
      <Stack.Screen
        name="mediaAd/[id]/index"
        options={{ title: t("mediaAd.title") }}
      />
      <Stack.Screen
        name="mediaAd/create"
        options={{ title: t("mediaAd.create.title") }}
      />
    </StackModal>
  );
}
