import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { Uploads } from "components/Uploads/Uploads";
import { useMutation } from "@apollo/client";

const MediaAdSetEnabled_Mutatuion = gql(`
  mutation MediaAdSetEnabled_Mutatuion($id: ObjectID!, $enabled: Boolean!) {
    mediaAdSetEnabled(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`);

const MediaAd_QueryFragment = gql(`
  fragment MediaAd_QueryFragment on Query {
    mediaAd(id: $id) {
      id
      name
      org {
        id
        ...GetListItem_OrgFragment
      }
      upload {
        ...UploadFragment
      }
      enabled
      impressionsCount
      viewsCount
    }
  }
`);

type CampaignReportProps = {
  query?: FragmentType<typeof MediaAd_QueryFragment>;
};

export const MediaAd = (props: CampaignReportProps) => {
  const query = getFragmentData(MediaAd_QueryFragment, props.query);
  const { t } = useTranslation("MediaAd");

  const [setMediaAdEnabled, { loading }] = useMutation(
    MediaAdSetEnabled_Mutatuion
  );

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.mediaAd?.org })],
      },
      {
        key: "status",
        data: [
          {
            key: "name",
            label: t("name"),
            loading: !query,
            title: query?.mediaAd?.name,
          },
          {
            key: "impressionsCount",
            label: t("impressionsCount"),
            loading: !query,
            title: query?.mediaAd?.impressionsCount,
          },
          {
            key: "viewsCount",
            label: t("viewsCount"),
            loading: !query,
            title: query?.mediaAd?.viewsCount,
          },
        ],
      },
      {
        key: "upload",
        title: t("upload"),
        data: [
          {
            key: "upload",
            Component: (
              <Uploads
                uploads={query?.mediaAd?.upload ? [query.mediaAd.upload] : []}
                loading={!query}
              />
            ),
          },
        ],
      },
      {
        key: "active",
        visible: !!query,
        data: [
          {
            key: "active",
            title: t(query?.mediaAd?.enabled ? "deactivate" : "makeActive"),
            centerContent: true,
            buttonType: query?.mediaAd?.enabled ? "destructive" : "primary",
            loading,
            onPress: () => {
              if (!query?.mediaAd) return;

              setMediaAdEnabled({
                variables: {
                  id: query.mediaAd.id,
                  enabled: !query.mediaAd.enabled,
                },
              });
            },
          },
        ],
      },
    ];
  }, [loading, query, setMediaAdEnabled, t]);

  return <SectionListMenu sections={sections} />;
};
