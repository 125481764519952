import { Platform, StyleSheet, Text } from "react-native";
import { cssClass } from "utils/css";
import { Link as ScrollLink } from "react-scroll";
import "./index.css";
import { ReactNode } from "react";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import GenericTouchable, {
  GenericTouchableProps,
} from "react-native-gesture-handler/lib/typescript/components/touchables/GenericTouchable";
import { ActivityIndicator } from "components/common";
import { ExpoRouter } from ".expo/types/router";
import { Link } from "expo-router";

export const Button = ({
  style,
  children,
  ...rest
}: React.PropsWithChildren<ExpoRouter.LinkProps>) => {
  return (
    <Link
      style={[cssClass("main-button"), styles.button, style]}
      numberOfLines={1}
      suppressHighlighting
      replace
      {...rest}
    >
      {children}
    </Link>
  );
};

export const LinkButton = ({
  children,
  style,
  to,
}: {
  children: ReactNode;
  style?: React.CSSProperties;
  to: string;
}) => {
  return (
    <ScrollLink
      className={cssClass("main-button")._}
      style={StyleSheet.flatten([styles.button, style])}
      href={"#" + to}
      to={to}
      containerId="home-scroll"
      smooth={true}
    >
      {children}
    </ScrollLink>
  );
};

export const BaseButton = ({
  children,
  style,
  loading,
  ...rest
}: GenericTouchableProps & {
  children?: React.ReactNode;
} & React.RefAttributes<GenericTouchable> & { loading?: boolean }) => {
  return (
    <TouchableWithoutFeedback
      style={[
        cssClass("main-button"),
        loading && cssClass("main-button-disabled"),
        loading && { opacity: 0.8 },
        styles.button,
        style,
      ]}
      disabled={loading}
      {...rest}
    >
      <Text style={[styles.text, loading && { opacity: 0 }]}>{children}</Text>
      {loading && (
        <ActivityIndicator
          style={styles.loading}
          color="rgba(77,174,220,1.00)"
        />
      )}
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    color: "black",
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 8,
    overflow: "hidden",
    display: "flex",
    textDecorationLine: "none",
    justifyContent: "center",

    ...Platform.select({
      default: {
        shadowColor: "rgba(0, 0, 0, 0.1)",
        shadowOffset: {
          width: 0,
          height: 5,
        },
        shadowOpacity: 1,
        shadowRadius: 5,
        elevation: 5,
      },
      web: { boxShadow: "0 5px 5px rgba(0, 0, 0, 0.1)" },
    }),
  },
  text: {
    fontSize: 14,
    fontWeight: "600",
  },
  loading: {
    position: "absolute",
    alignSelf: "center",
  },
});
