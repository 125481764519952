import { persistReducer } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguages } from "utils/i18n/resources";
import { RootState } from "store";
import { getInitialLang } from "utils/i18n";
import { storage } from "utils/storage";
import config from "config";

export type SettingsState = Readonly<{
  apiUrl: string;
  customApiUrls: string[];
  theme: "auto" | "light" | "dark";
  lang: SupportedLanguages;
  lastAppLikedShownAt?: number;
  currentOrgId?: string;
}>;

const initialState: SettingsState = {
  apiUrl: config.api.production,
  customApiUrls: [],
  theme: "auto",
  lang: getInitialLang(),
};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<SettingsState["lang"]>) => {
      state.lang = action.payload;
    },
    setLastAppLikedShown: (state) => {
      state.lastAppLikedShownAt = Date.now();
    },
    setApiUrl: (state, action: PayloadAction<string>) => {
      state.apiUrl = action.payload;
    },
    setCustomApiUrls: (state, action: PayloadAction<string[]>) => {
      state.customApiUrls = action.payload;
    },
    setCurrentOrgId: (state, action: PayloadAction<string>) => {
      state.currentOrgId = action.payload;
    },
  },
});

export const {
  setLang,
  setLastAppLikedShown,
  setApiUrl,
  setCustomApiUrls,
  setCurrentOrgId,
} = settings.actions;

export const selectApiUrl = (state: RootState) => state.settings.apiUrl;
export const selectCustomApiUrls = (state: RootState) =>
  state.settings.customApiUrls;

export const selectLang = (state: RootState) => state.settings.lang;

export const selectShowAppLike = (state: RootState) => {
  const { lastAppLikedShownAt } = state.settings;
  if (!lastAppLikedShownAt) return true;

  const now = Date.now();
  const diff = now - lastAppLikedShownAt;
  const days = diff / (1000 * 60 * 60 * 24);

  return days > 7;
};

export const selectTheme = (state: RootState) => state.settings.theme;
export const selectCurrentOrgId = (state: RootState) =>
  state.settings.currentOrgId;

export default {
  reducer: persistReducer(
    {
      key: "settings",
      storage,
    },
    settings.reducer
  ),
};
