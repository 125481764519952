import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { MediaAdList } from "components/MediaAd/MediaAdList";

const ControlMediaAds_Query = gql(`
  query ControlMediaAds_Query {
    ...MediaAdList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlMediaAds_Query);

  return <MediaAdList query={data} />;
}
