import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Campaign } from "components/Campaign/Campaign";
import { HeaderButton } from "components/common";
import { Stack, useLocalSearchParams } from "expo-router";
import { useTranslation } from "react-i18next";

export const Campaign_Query = gql(`
  query Campaign_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    ...Campaign_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Campaign_Query, { variables: { id } });

  const { t: g } = useTranslation();

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: () => (
            <HeaderButton text={g("edit")} href={`/campaign/${id}/edit`} />
          ),
        }}
      />
      <Campaign query={data} />
    </>
  );
}
