import { useMutation } from "@apollo/client";
import { gql } from "__generated__";
import { SectionListMenu, SectionListMenuItem } from "components/common";
import { router, useLocalSearchParams } from "expo-router";
import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
  parseDigits,
} from "libphonenumber-js";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const AddCarDriver_Mutation = gql(`
  mutation AddCarDriver_Mutation($carId: ObjectID!, $phone: String!) {
    addCarDriver(carId: $carId, phone: $phone) {
      id
    }
  }
`);

export const CarDriverAdd = () => {
  const { id = "" } = useLocalSearchParams<{ id: string }>();

  const { t } = useTranslation("CarDriverAdd");
  const { t: g } = useTranslation();

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const _phone = useMemo(() => {
    return formatIncompletePhoneNumber(
      phone.length ? (/^\+/.test(phone) ? phone : "+" + phone) : ""
    );
  }, [phone]);

  const [addDriver, { loading }] = useMutation(AddCarDriver_Mutation, {
    onCompleted() {
      router.back();
    },
  });

  const phoneRef = useRef<SectionListMenuItem>(null);

  const submit = useCallback(() => {
    let haveError = false;
    if (!phone.length) {
      haveError = true;
      setPhoneError(t("phone.error.empty"));
      phoneRef.current?.shake();
    } else {
      if (!isPossiblePhoneNumber("+" + phone)) {
        haveError = true;
        setPhoneError(t("phone.error.invalid"));
        phoneRef.current?.shake();
      }
    }

    if (haveError) return;

    addDriver({
      variables: {
        carId: id,
        phone,
      },
    });
  }, [addDriver, id, phone, t]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "phone",
        title: t("phone.label"),
        error: phoneError,
        data: [
          {
            key: "phone",
            ref: phoneRef,
            type: "textInput",
            textInputProps: {
              value: _phone,
              onChangeText: (phone) => {
                setPhoneError("");
                setPhone(parseDigits(phone));
              },
              keyboardType: "phone-pad",
              textContentType: "telephoneNumber",
              editable: !loading,
            },
          },
        ],
      },
      {
        key: "submit",
        data: [
          {
            key: "submit",
            style: {
              marginTop: 16,
            },
            buttonType: "primary",
            title: g("add"),
            centerContent: true,
            onPress: submit,
            loading: loading,
          },
        ],
      },
    ];
  }, [_phone, g, loading, phoneError, submit, t]);

  return <SectionListMenu sections={sections} />;
};
