import {
  Campaigns_QueryDocument,
  CarPicker_QueryDocument,
  Cars_QueryDocument,
  ControlApps_QueryDocument,
  ControlCars_QueryDocument,
  ControlMediaAds_QueryDocument,
  ControlOrgs_QueryDocument,
  ControlUsers_QueryDocument,
  OrgMembers_QueryDocument,
  OrgPicker_QueryDocument,
  Settings_QueryDocument,
  Tabs_QueryDocument,
} from "__generated__/graphql";
import { DocumentNode } from "graphql";

export const refetchQueries: { [key: string]: DocumentNode[] } = {
  MediaAd: [ControlMediaAds_QueryDocument],
  App: [ControlApps_QueryDocument],
  Org: [
    OrgPicker_QueryDocument,
    Tabs_QueryDocument,
    ControlOrgs_QueryDocument,
    OrgMembers_QueryDocument,
    Cars_QueryDocument,
    Campaigns_QueryDocument,
    Settings_QueryDocument,
  ],
  User: [ControlUsers_QueryDocument],
  Car: [CarPicker_QueryDocument, Cars_QueryDocument, ControlCars_QueryDocument],
};
