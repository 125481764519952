import { useCallback } from "react";
import useActionSheet, { UseActionSheetValue } from "./useActionSheet";
import {
  DocumentPickerAsset,
  DocumentPickerOptions,
  getDocumentAsync,
} from "expo-document-picker";
import {
  ImagePickerAsset,
  ImagePickerOptions,
  launchCameraAsync,
  launchImageLibraryAsync,
  requestCameraPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
} from "expo-image-picker";
import { Platform, toast } from "components/common";
import { useTranslation } from "react-i18next";

export type UseAssetPickerProps = {
  multiple?: boolean;
  selectionLimit?: number;
  camera?: false | ImagePickerOptions;
  mediaLibrary?: false | ImagePickerOptions;
  doc?: false | DocumentPickerOptions;
};
export const useAssetPicker = (props?: UseAssetPickerProps) => {
  const { t } = useTranslation("hooks", { keyPrefix: "useAssetPicker" });
  const showSheet = useActionSheet();

  const show = useCallback(() => {
    return new Promise<ImagePickerAsset[] | DocumentPickerAsset[]>(
      (resolve) => {
        const options: UseActionSheetValue[] = [];

        Platform.OS !== "web" &&
          props?.camera !== false &&
          options.push({
            text: t("takePhoto"),
            onPress: () => {
              requestCameraPermissionsAsync().then((result) => {
                if (!result.granted) toast(t("camera.permissionDenied"));
                else
                  launchCameraAsync({
                    allowsMultipleSelection: props?.multiple,
                    selectionLimit: props?.selectionLimit,
                    ...props?.camera,
                  }).then((result) => {
                    if (result.canceled) return;

                    resolve(result.assets);
                  });
              });
            },
          });

        Platform.OS !== "web" &&
          props?.mediaLibrary !== false &&
          options.push({
            text: t("chooseFromGallery"),
            onPress: () => {
              requestMediaLibraryPermissionsAsync().then((result) => {
                if (!result.granted) toast(t("gallery.permissionDenied"));
                else
                  launchImageLibraryAsync({
                    allowsMultipleSelection: props?.multiple,
                    selectionLimit: props?.selectionLimit,
                    ...props?.mediaLibrary,
                  }).then((result) => {
                    if (result.canceled) return;

                    resolve(result.assets);
                  });
              });
            },
          });

        props?.doc !== false &&
          options.push({
            text: t("chooseDocument"),
            onPress: () => {
              getDocumentAsync({
                multiple: props?.multiple,
                ...props?.doc,
              }).then((result) => {
                if (result.canceled) return;

                resolve(
                  props?.selectionLimit
                    ? result.assets.slice(0, props.selectionLimit)
                    : result.assets
                );
              });
            },
          });

        if (options.length === 1) options[0].onPress?.();
        else showSheet(options);
      }
    );
  }, [
    props?.camera,
    props?.doc,
    props?.mediaLibrary,
    props?.multiple,
    props?.selectionLimit,
    showSheet,
    t,
  ]);

  return [show] as const;
};
