import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Org } from "components/Org/Org";
import { HeaderButton } from "components/common";
import { Stack, useLocalSearchParams } from "expo-router";
import { useTranslation } from "react-i18next";

export const Org_Query = gql(`
  query Org_Query($id: ObjectID!) {
    org(id: $id) {
      id
      member {
        id
        role
      }
    }
    ...Org_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Org_Query, { variables: { id } });
  const { t: g } = useTranslation();

  return (
    <>
      <Stack.Screen
        options={{
          headerRight:
            data?.org?.member?.role === "owner"
              ? () => (
                  <HeaderButton text={g("edit")} href={`/org/${id}/settings`} />
                )
              : undefined,
        }}
      />
      <Org query={data} />
    </>
  );
}
