import { FragmentType, gql, getFragmentData } from "__generated__";
import { StyleSheet, Wrap } from "components/common";
import { AppIcon } from "./AppIcon";

const Header_AppFragment = gql(`
  fragment Header_AppFragment on App {
    id
    ...Icon_AppFragment
  }
`);

type AppHeaderProps = {
  app?: FragmentType<typeof Header_AppFragment> | null;
};

export const AppHeader = (props: AppHeaderProps) => {
  const app = getFragmentData(Header_AppFragment, props.app);

  return (
    <Wrap style={styles.header}>
      <AppIcon app={app} />
    </Wrap>
  );
};

const styles = StyleSheet.create({
  header: {
    gap: 8,
    paddingTop: 8,
    paddingBottom: 16,
    alignItems: "center",
  },
});
