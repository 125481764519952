import { useOnline } from "hooks/useOnline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text } from "react-native";
import Animated, { SlideInUp, SlideOutUp } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const NetworkError = () => {
  const { t } = useTranslation("common");
  const insets = useSafeAreaInsets();
  const online = useOnline();

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    let tmr: NodeJS.Timeout;
    if (online) setIsOnline(true);
    else
      tmr = setTimeout(() => {
        setIsOnline(false);
      }, 1000);
    return () => clearTimeout(tmr);
  }, [online]);

  if (isOnline) return null;

  return (
    <Animated.View
      style={[styles.container, { paddingTop: insets.top - 14 }]}
      entering={SlideInUp.duration(1000)}
      exiting={SlideOutUp.duration(1000)}
    >
      <Text style={styles.text}>{t("NetworkError.connecting")}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: "#FF9500",
    zIndex: 1000,
    width: "100%",
    pointerEvents: "none",
  },
  text: {
    color: "white",
    textAlign: "center",
    fontSize: 12,
    paddingVertical: 3,
    fontWeight: "500",
  },
});
