import { gql } from "__generated__";

export const MediaAdDraft_Query = gql(`
  query MediaAdDraft_Query {
    mediaAdDraft @client {
      orgId
      name
      enabled
      upload {
        id
        ...UploadFragment
      }
    }
  }
`);
