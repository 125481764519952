import { useMemo } from "react";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getAppListItem } from "./getAppListItem";
import { useTranslation } from "react-i18next";
import { Link } from "expo-router";

const AppList_QueryFragment = gql(`
  fragment AppList_QueryFragment on Query {
    apps {
      ...GetListItem_AppFragment
    }
  }
`);

type AppListProps = {
  query?: FragmentType<typeof AppList_QueryFragment>;
};

export const AppList = (props: AppListProps) => {
  const query = getFragmentData(AppList_QueryFragment, props.query);
  const { t } = useTranslation("AppList");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "create",
        data: [
          {
            key: "create",
            buttonType: "primary",
            centerContent: true,
            title: t("create"),
            Link: <Link href="/app/create" />,
          },
        ],
      },
      {
        key: "users",
        data: query?.apps?.map((app) => getAppListItem({ app })) || [
          getAppListItem(),
        ],
      },
    ];
  }, [query?.apps, t]);

  return <SectionListMenu sections={sections} />;
};
