import { FragmentType, getFragmentData, gql } from "__generated__";
import { Photo, PhotoProps } from "components/Photo";
import UserIcon from "assets/icons/user.svg";

const Photo_UserFragment = gql(`
  fragment Photo_UserFragment on User {
    id
  }
`);

type UserPhotoProps = Omit<PhotoProps, "type"> & {
  user?: FragmentType<typeof Photo_UserFragment> | null;
};

export const UserPhoto = (props: UserPhotoProps) => {
  const user = getFragmentData(Photo_UserFragment, props.user);
  // eslint-disable-next-line no-unused-expressions
  user;

  return <Photo {...props} Icon={UserIcon} loading={!props.user} />;
};
