import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import OrgMemberEdit from "components/OrgMember/OrgMemberEdit";
import { useLocalSearchParams } from "expo-router";

export const OrgMemberEdit_Query = gql(`
  query OrgMemberEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    orgMember(id: $id) {
      id
      role
      inviteStatus
    }
    ...OrgMemberEdit_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(OrgMemberEdit_Query, { variables: { id } });

  return <OrgMemberEdit query={data} />;
}
