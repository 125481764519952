import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
  split,
} from "@apollo/client";
import { AsyncStorageWrapper, persistCache } from "apollo3-cache-persist";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { dispatch, store } from "store";
import { toast } from "components/common";
import { storage } from "./storage";
import i18next, { t } from "i18next";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { isServer } from "constants/Device";
import { updateDevice } from "store/device";
import { getDeviceInfo } from "./device";

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

const retryLink = new RetryLink({
  delay: {
    initial: 500, // Начальная задержка между попытками
    max: Infinity, // Максимальная задержка между попытками
    jitter: true, // Случайное распределение задержек для избегания одновременных повторных запросов
  },
  attempts: {
    max: Infinity, // Максимальное количество попыток
    retryIf: (error) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!error?.statusCode) {
        console.log("retry", error);
        return true;
      }

      return false;
    },
  },
});

const resetErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return response;
  });
});

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      const code = extensions?.code as string;
      const text = i18next.t(`operations:${operation.operationName}.${code}`, {
        defaultValue: t("operations:error", {
          operationName: operation.operationName,
          message,
          code,
        }),
      });

      typeof text === "string" && toast(text);
      console.log(JSON.stringify(graphQLErrors, null, 2));
    });
  }
});

const httpLink = new HttpLink({
  uri: (operation) => {
    const state = store.getState();
    const uri = state.settings.apiUrl + "/" + operation.operationName;
    return uri;
  },
});

const wsLink = isServer
  ? undefined
  : new GraphQLWsLink(
      createClient({
        url: () => {
          const state = store.getState();
          const uri = state.settings.apiUrl.replace("http", "ws");
          return uri;
        },
        connectionParams: () => {
          const state = store.getState();
          const token = state.auth.token;
          const device = getDeviceInfo();

          return {
            device,
            token,
          };
        },
        on: {
          connecting: () => {
            console.log("connecting");
            dispatch(updateDevice({ online: false }));
          },
          connected: () => {
            console.log("connected");
            dispatch(updateDevice({ online: true }));
          },
        },
      })
    );

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink || httpLink,
  httpLink
);

const authLink = new ApolloLink((operation, forward) => {
  const state = store.getState();
  const token = state.auth.token;
  const device = getDeviceInfo();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(device ? { Device: JSON.stringify(device) } : {}),
    },
  }));

  return forward(operation);
});

export const cache = new InMemoryCache();

process.env.NODE_ENV !== "development" &&
  persistCache({
    cache,
    storage: new AsyncStorageWrapper(storage),
  });

const successLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!response.errors) {
      operation.query.definitions.forEach((definition) => {
        if ("operation" in definition && definition.operation === "mutation") {
          const defaultValue = t("operations:default", {
            operationName: operation.operationName,
          });
          const successText = i18next.t(
            `operations:${operation.operationName}.success`,
            { defaultValue }
          );
          const operationText = i18next.t(
            `operations:${operation.operationName}`,
            {
              defaultValue,
            }
          );
          const text = operationText === false ? false : successText;
          typeof text === "string" && toast(text);
        }
      });
    }
    return response;
  });
});

export const appoloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    resetErrorLink,
    retryLink,
    successLink,
    splitLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
