import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu, Switch } from "components/common";
import { Uploads } from "components/Uploads/Uploads";
import { useDraft } from "components/drafts/drafts";
import { getFragmentData, gql } from "__generated__";
import { UploadFragment } from "components/Uploads/Upload";
import { useMutation } from "@apollo/client";
import { router } from "expo-router";
import { refetchQueries } from "utils/refetchQueries";

const AppCreate_Mutatuion = gql(`
  mutation AppCreate_Mutatuion($data: AppCreateInput!) {
    appCreate(data: $data) {
      id
      active
    }
  }
`);

export const AppCreate = () => {
  const { t: g } = useTranslation();
  const { t } = useTranslation("AppCreate");

  const [draft, setDraft] = useDraft("appDraft");
  const [uploading, setUploading] = useState(false);

  const [createApp, { loading }] = useMutation(AppCreate_Mutatuion, {
    refetchQueries: refetchQueries.App,
    onCompleted() {
      router.back();
      setDraft();
    },
  });

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "file",
        title: t("file.label"),
        data: [
          {
            key: "file",
            Component: (
              <Uploads
                editable
                uploads={draft.upload ? [draft.upload] : []}
                onChange={(uploads) => {
                  const upload = getFragmentData(UploadFragment, uploads[0]);
                  setDraft({
                    upload: {
                      id: upload?.id,
                      ...uploads[0],
                    },
                  });
                }}
                onUploadingStateChange={setUploading}
                options={{
                  multiple: false,
                  camera: false,
                  mediaLibrary: false,
                  doc: { type: "application/vnd.android.package-archive" },
                }}
              />
            ),
          },
        ],
      },
      {
        key: "info",
        visible: !!draft.upload,
        data: [
          {
            key: "label",
            label: t("label"),
            title: draft.upload?.app?.label,
          },
          {
            key: "package",
            label: t("package"),
            title: draft.upload?.app?.package,
          },
          {
            key: "versionName",
            label: t("versionName"),
            title: draft.upload?.app?.versionName,
          },
          {
            key: "versionCode",
            label: t("versionCode"),
            title: draft.upload?.app?.versionCode,
          },
        ],
      },
      {
        key: "active",
        data: [
          {
            key: "active",
            title: t("makeActive"),
            TrailingComponent: (
              <Switch
                value={draft.active || false}
                onValueChange={(active) => setDraft({ active })}
              />
            ),
          },
        ],
      },
      {
        key: "create",
        data: [
          {
            key: "create",
            title: g(uploading ? "filesUploading" : "add"),
            buttonType: "primary",
            centerContent: true,
            disabled: uploading || !draft.upload,
            loading,
            onPress: () => {
              if (!draft.upload || !draft.upload.app) return;

              createApp({
                variables: {
                  data: {
                    uploadId: draft.upload.id,
                    ...draft.upload.app,
                    active: draft.active,
                  },
                },
              });
            },
          },
        ],
      },
    ];
  }, [
    t,
    draft.upload,
    draft.active,
    g,
    uploading,
    loading,
    setDraft,
    createApp,
  ]);

  return <SectionListMenu sections={sections} />;
};
