import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { AppList } from "components/App/AppList";

const ControlApps_Query = gql(`
  query ControlApps_Query {
    ...AppList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlApps_Query);

  return <AppList query={data} />;
}
