import { StyleSheet, Text, View } from "react-native";
import { Title } from "../common/Title";
import { numberFormat } from "utils/format";
import { Safe } from "../common/Safe";
import "./index.css";
import { cssClass } from "utils/css";
import {
  darkColor,
  darkSecondaryColor,
  linkColor,
  primaryColor,
} from "../Constants";
import { Section } from "../common/Section";
import { Link } from "react-scroll";

export const About = () => {
  return (
    <Section id="about" style={styles.container}>
      <Safe>
        <Title>О нас</Title>
        <View style={[cssClass("about-rows"), styles.rows]}>
          <View
            style={[
              cssClass("about-row"),
              styles.row,
              styles.descriptionContainer,
            ]}
          >
            <Text style={[cssClass("about-description"), styles.description]}>
              Компания «Инкар Медиа» является лидером в сегменте рекламы в
              такси. За 5 лет работы мы накопили богатый опыт и заслужили
              безупречную репутацию.{"\n\n"}В комплекс наших услуг входит
              размещение рекламы на цифровых мониторах и шелфтокерах в салоне
              автомобилей такси.
              {"\n\n"}Мы сотрудничаем как с крупными рекламодателями, так и с
              небольшими компаниями. В числе наших{" "}
              <Link
                style={styles.link}
                href="#clients"
                to="clients"
                containerId="home-scroll"
                smooth={true}
              >
                клиентов
              </Link>{" "}
              такие компании, как: А1, МТС, Альфа-Банк, МТБанк, Burger King,
              KFC, FONBET, Евроопт и многие другие.
            </Text>
          </View>
          <View style={[cssClass("about-row"), styles.row, styles.items]}>
            {[
              { count: 5, text: "лет на рынке" },
              { count: 7, text: "городов размещения" },
              { count: 1250, text: "автомобилей с рекламой" },
              { count: 4000000, text: "пассажиров" },
              { count: 100, text: "% довольных клиентов" },
            ].map((_, index) => (
              <View key={index} style={[cssClass("about-item"), styles.item]}>
                <View>
                  <Text style={styles.itemCountText}>
                    {numberFormat(_.count)}
                  </Text>
                  <View style={styles.itemCountBorder} />
                </View>
                <Text style={styles.itemText}>{_.text}</Text>
              </View>
            ))}
          </View>
        </View>
      </Safe>
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  rows: {
    flexDirection: "row",
    gap: 32,
  },
  row: {
    flex: 1,
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: "center",
  },
  description: {
    fontSize: 16,
    maxWidth: 440,
    lineHeight: 22,
    color: darkSecondaryColor,
  },
  items: {
    gap: 21,
  },
  item: {
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 8,
  },
  itemCountText: {
    fontSize: 42,
    fontWeight: "700",
    color: darkColor,
  },
  itemCountBorder: {
    height: 10,
    backgroundColor: primaryColor,
    marginTop: -19,
    zIndex: -1,
  },
  itemText: {
    fontSize: 16,
    marginBottom: -3,
    color: darkSecondaryColor,
  },
  link: {
    color: linkColor,
    textDecorationLine: "none",
  },
});
