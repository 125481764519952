import { Image } from "expo-image";
import { RectButton } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";

export {
  StyleSheet,
  ListRenderItem,
  FlatList,
  TouchableOpacity,
  useColorScheme,
  SwitchProps,
  Platform,
  ScrollView,
} from "react-native";
export { Image } from "expo-image";
export * from "./Button";
export * from "./Themed";
export * from "./Toast";
export * from "./BlurView";
export * from "./KeyboardAvoidingView";
export * from "./Separator";
export * from "./Wrap";
export * from "./StackModal";
export * from "./PhoneInput";
export * from "./SectionListMenu";
export * from "./Shimmer";
export * from "./Switch";
export * from "./UploadsManager";
export * from "./UploadProgressIndicator";
export * from "./NetworkError";
export * from "./picker";

export const AnimatedRectButton = Animated.createAnimatedComponent(RectButton);
export const AnimatedImage = Animated.createAnimatedComponent(Image);
