import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { MediaAd } from "components/MediaAd/MediaAd";
import { useLocalSearchParams } from "expo-router";

const MediaAd_Query = gql(`
  query MediaAd_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {
    ...MediaAd_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(MediaAd_Query, { variables: { id } });

  return <MediaAd query={data} />;
}
