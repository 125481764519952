import { FragmentType, getFragmentData, gql } from "__generated__";
import {
  BlurView,
  Image,
  RectButton,
  StyleSheet,
  Text,
  View,
  useColors,
} from "components/common";
import Animated, {
  FadeIn,
  LinearTransition,
  ZoomIn,
  ZoomOut,
} from "react-native-reanimated";
import DocIcon from "assets/icons/doc.svg";
import { useState } from "react";
import { Link } from "expo-router";
import MinusIcon from "assets/icons/minus.svg";
import PlayIcon from "assets/icons/play.svg";
import { UploadProgress } from "./UploadProgress";
import { getTypeByMime } from "utils/mime";

export const UploadFragment = gql(`
  fragment UploadFragment on Upload {
    id
    fileName
    mime
    signedUrl
    cloudType
    thumbhash
    url
    previewUrl: url(size: 200)
    localUri @client
    progress @client {
      progress
    }
    app @client {
      label
      package
      versionName
      versionCode
      icon
    }
    ...UploadProgressFragment
  }
`);

export type UploadProps = {
  upload?: FragmentType<typeof UploadFragment>;
  editable?: boolean;
  onRemove?: () => void;
};

export const Upload = (props: UploadProps) => {
  const upload = getFragmentData(UploadFragment, props.upload);
  const colors = useColors();
  const [error, setError] = useState(false);

  const type = getTypeByMime(upload?.mime);
  const uri =
    upload?.app?.icon ||
    upload?.previewUrl ||
    (type === "image" ? upload?.localUri : undefined) ||
    undefined;

  const ext = upload?.fileName?.split(".").pop()?.toLowerCase();
  const name = upload?.fileName?.replace(/\.\w+$/, "");
  return (
    <Animated.View
      style={styles.container}
      entering={FadeIn}
      exiting={ZoomOut}
      layout={LinearTransition}
    >
      <View style={styles.wrap} colorName="card">
        {uri && (
          <Image
            style={styles.image}
            placeholder={{ thumbhash: upload?.thumbhash || undefined }}
            source={{ uri }}
            transition={300}
            onError={() => {
              setError(true);
            }}
          />
        )}
        {!!upload?.url && (
          <Link
            style={styles.button}
            href={upload.url as `${string}:${string}`}
            target="_blank"
          >
            <RectButton style={styles.button} />
          </Link>
        )}
        {(error || type === "raw") && !upload?.app?.icon ? (
          <Animated.View style={styles.image} entering={FadeIn}>
            <DocIcon color={colors.textSecondary} width="50%" height="50%" />
            <View style={styles.fileName}>
              <Text style={styles.fileNameName} numberOfLines={1}>
                {name}
              </Text>
              <Text style={styles.fileNameExt} numberOfLines={1}>
                .{ext}
              </Text>
            </View>
          </Animated.View>
        ) : type === "video" && !upload?.progress ? (
          <Animated.View style={styles.image} entering={FadeIn}>
            <View style={styles.playIcon}>
              <PlayIcon
                style={{ marginRight: -4 }}
                color="#fffe"
                width="50%"
                height="50%"
              />
            </View>
          </Animated.View>
        ) : null}

        {upload?.progress && <UploadProgress upload={upload} />}
        {(upload?.url || !upload?.progress) && props.editable && (
          <Animated.View
            style={styles.remove}
            entering={ZoomIn}
            exiting={ZoomOut}
          >
            <BlurView style={styles.removeBlur} tint="dark">
              <RectButton
                style={styles.removeButton}
                onPress={() => {
                  props.onRemove?.();
                }}
              >
                <MinusIcon color="white" />
              </RectButton>
            </BlurView>
          </Animated.View>
        )}
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 90,
    width: `${100 / 4}%`,
    aspectRatio: 1,
    padding: 4,
  },
  wrap: {
    flex: 1,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 8,
    gap: 4,
    pointerEvents: "none",
  },

  remove: {
    position: "absolute",
    width: 26,
    height: 26,
    borderRadius: 26 / 2,
    overflow: "hidden",
    left: -6,
    top: -6,
  },
  removeBlur: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 26 / 2,
    overflow: "hidden",
  },
  removeButton: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  fileName: {
    flexDirection: "row",
    maxWidth: "100%",
  },
  fileNameName: {
    flex: 1,
  },
  fileNameExt: {},
  playIcon: {
    backgroundColor: "#00000080",
    height: "50%",
    aspectRatio: 1,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});
