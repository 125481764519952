import { useQuery } from "@apollo/client";
import { CarEdit } from "components/Car/CarEdit";
import { useLocalSearchParams } from "expo-router";
import { Car_Query } from ".";

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Car_Query, { variables: { id } });

  return <CarEdit query={data} />;
}
