import { useMemo } from "react";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getMediaAdListItem } from "./getMediaAdListItem";
import { useTranslation } from "react-i18next";
import { Link } from "expo-router";

const MediaAdList_QueryFragment = gql(`
  fragment MediaAdList_QueryFragment on Query {
    mediaAds {
      ...GetListItem_MediaAdFragment
    }
  }
`);

type CampaignReportListProps = {
  query?: FragmentType<typeof MediaAdList_QueryFragment>;
};

export const MediaAdList = (props: CampaignReportListProps) => {
  const query = getFragmentData(MediaAdList_QueryFragment, props.query);
  const { t } = useTranslation("MediaAdList");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "create",
        data: [
          {
            key: "create",
            buttonType: "primary",
            centerContent: true,
            title: t("create"),
            Link: <Link href="/mediaAd/create" />,
          },
        ],
      },
      {
        key: "ads",
        data: query?.mediaAds?.map((ad) =>
          getMediaAdListItem({
            ad,
            showOrgName: true,
            showCampaignName: true,
          })
        ) || [getMediaAdListItem()],
      },
    ];
  }, [query?.mediaAds, t]);

  return <SectionListMenu sections={sections} />;
};
