import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Device } from "components/Device/Device";
import { useLocalSearchParams } from "expo-router";
import { useDeviceSubscription } from "hooks/useDeviceSubscription";

const Device_Query = gql(`
  query Device_Query($id: String) {
    ...Device_QueryFragment
  }
`);

export default function Page() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Device_Query, { variables: { id } });
  useDeviceSubscription(id);

  return <Device query={data} />;
}
