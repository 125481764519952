import { gql } from "__generated__";

export const AppDraft_Query = gql(`
  query AppDraft_Query {
    appDraft @client {
      upload {
        id
        app {
          label
          package
          versionName
          versionCode
          icon
        }
        ...UploadFragment
      }
      active @client
    }
  }
`);
