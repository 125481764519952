import { useMemo } from "react";
import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getDeviceListItem } from "./getDeviceListItem";

const DeviceList_QueryFragment = gql(`
  fragment DeviceList_QueryFragment on Query {
    devices {
      ...GetListItem_DeviceFragment
    }
  }
`);

type DeviceListProps = {
  query?: FragmentType<typeof DeviceList_QueryFragment>;
};

export const DeviceList = (props: DeviceListProps) => {
  const query = getFragmentData(DeviceList_QueryFragment, props.query);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "devices",
        data: query?.devices?.map((device) =>
          getDeviceListItem({ device })
        ) || [getDeviceListItem()],
      },
    ];
  }, [query?.devices]);

  return <SectionListMenu sections={sections} />;
};
