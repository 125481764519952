import { FragmentType, getFragmentData, gql } from "__generated__";
import { SectionListMenuItemProps, UserContent } from "components/common";
import { Link } from "expo-router";
import { AppIcon } from "./AppIcon";
import { getFixedT } from "i18next";

const GetListItem_AppFragment = gql(`
  fragment GetListItem_AppFragment on App {
    id
    label
    package
    versionName
    versionCode
    active
    ...Icon_AppFragment
  }
`);

type AppListItemProps = {
  app?: FragmentType<typeof GetListItem_AppFragment> | null;
};

export const getAppListItem = (
  props?: AppListItemProps
): SectionListMenuItemProps => {
  const app = getFragmentData(GetListItem_AppFragment, props?.app);
  const t = getFixedT(null, "getAppListItem");

  return {
    key: app?.id || "app",
    Link: app ? <Link href={`/app/${app.id}/`} /> : undefined,
    LeadingComponent: <AppIcon app={app} size={40} />,
    leadingWidth: 40,
    Content: (
      <UserContent
        title={app?.label}
        subtitle={[
          app?.package || " ",
          `${app?.versionName} (${app?.versionCode})`,
        ]}
        loading={!app}
      />
    ),
    trailingText: app?.active ? t("active") : undefined,
  };
};
