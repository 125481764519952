import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Ad } from "components/Ad/Ad";
import { useLocalSearchParams } from "expo-router";

const Ad_Query = gql(`
  query Ad_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {
    ...Ad_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Ad_Query, { variables: { id } });

  return <Ad query={data} />;
}
