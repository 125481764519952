import { forwardRef, useCallback, useMemo } from "react";

import {
  KeyboardAvoidingView,
  Platform,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  StyleSheet,
} from "react-native";
import {
  SectionListMenuItem,
  SectionListMenuItemProps,
} from "./SectionListMenuItem";
// import { SectionSeparator } from "./SectionSeparator";
import { Separator } from "./Separator";
import { renderSectionHeader } from "./SectionHeader";
import { SectionFooter } from "./SectionFooter";
import Animated from "react-native-reanimated";
import { AnimatedCell } from "./AnimatedCell";
import { useHeaderHeight } from "@react-navigation/elements";
import { BottomSheetSectionList } from "@gorhom/bottom-sheet";

export type SectionListMenuSection = {
  key: string;
  title?: string;
  description?: string;
  error?: string;
  visible?: boolean;
  data: SectionListMenuItemProps[];
};
export type SectionListMenu = SectionListData<
  SectionListMenuItemProps,
  SectionListMenuSection
>;

const AnimatedSectionList = Animated.createAnimatedComponent(
  SectionList<SectionListMenuItemProps, SectionListMenu>
);

export type RenderSectionFooterInfo = {
  section: SectionListMenuSection;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionListMenu = forwardRef<
  SectionList<SectionListMenuItemProps, SectionListMenu>,
  SectionListProps<SectionListMenuItemProps, SectionListMenu> & {
    bottomSheet?: boolean;
  }
>(function SectionListMenu({ bottomSheet, ...props }, ref) {
  const sections = useMemo(() => {
    return (
      // eslint-disable-next-line react/prop-types
      props.sections
        // eslint-disable-next-line react/prop-types
        .filter((section) => section.visible !== false)
        .map((_) => ({ ..._, data: _.data.filter((_) => _.visible !== false) }))
    );
    // eslint-disable-next-line react/prop-types
  }, [props.sections]);

  const renderSectionFooter = useCallback(
    ({ section }: RenderSectionFooterInfo) => {
      const index = sections.indexOf(section);
      const trailingSection = sections[index + 1];

      return (
        <SectionFooter
          style={[{ marginBottom: trailingSection?.title ? 12 : 24 }]}
          {...section}
        />
      );
    },
    [sections]
  );

  const renderItem: SectionListRenderItem<
    SectionListMenuItemProps,
    SectionListMenu
  > = useCallback(
    ({ item, section, index, separators }) => {
      const isFirst = index === 0;
      const isLast = index === section.data.length - 1;
      const sectionIndex = sections.indexOf(section);
      const trailingSection = sections[sectionIndex + 1];

      return (
        <SectionListMenuItem
          {...item}
          style={[
            isLast &&
              !section.description &&
              !section.error && {
                marginBottom: trailingSection?.title ? 12 : 24,
              },
            item.style,
          ]}
          isFirst={isFirst}
          isLast={isLast}
          onActiveStateChange={(active) =>
            active ? separators.highlight() : separators.unhighlight()
          }
        />
      );
    },
    [sections]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const headerHeight = bottomSheet ? 0 : useHeaderHeight();

  const Component =
    bottomSheet && Platform.OS !== "web"
      ? BottomSheetSectionList
      : AnimatedSectionList;

  return (
    <KeyboardAvoidingView
      style={styles.avoidingView}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={headerHeight}
    >
      <Component
        ref={ref}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        contentInsetAdjustmentBehavior="automatic"
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        stickySectionHeadersEnabled={false}
        CellRendererComponent={AnimatedCell}
        {...props}
        sections={sections}
      />
    </KeyboardAvoidingView>
  );
});

const styles = StyleSheet.create({
  avoidingView: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 16,
  },
});

export * from "./SectionListMenuItem";
export * from "./UserContent";
