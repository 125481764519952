import { FragmentType, getFragmentData, gql } from "__generated__";
import { SectionListMenuItemProps, UserContent } from "components/common";
import { Link } from "expo-router";
import { useDeviceSubscription } from "hooks/useDeviceSubscription";

const GetListItem_DeviceFragment = gql(`
  fragment GetListItem_DeviceFragment on Device {
    id
    appId
    platform
    appVersion
    online
  }
`);

type OrgUserListItemProps = {
  device?: FragmentType<typeof GetListItem_DeviceFragment> | null;
};

export const getDeviceListItem = (
  props?: OrgUserListItemProps
): SectionListMenuItemProps => {
  const device = getFragmentData(GetListItem_DeviceFragment, props?.device);

  return {
    key: device?.id || "device",
    Link: device ? <Link href={`/device/${device.id}/`} /> : undefined,
    leadingWidth: 40,
    Content: <Content device={props?.device} />,
    trailingText: device?.online ? "Online" : undefined,
  };
};

type ContentProps = {
  device?: FragmentType<typeof GetListItem_DeviceFragment> | null;
};

const Content = (props: ContentProps) => {
  const device = getFragmentData(GetListItem_DeviceFragment, props?.device);

  useDeviceSubscription(device?.id);

  return (
    <UserContent
      title={device?.platform}
      subtitle={[device?.appId || " ", `v${device?.appVersion}`]}
      loading={!device}
    />
  );
};
