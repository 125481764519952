import { FragmentType, getFragmentData, gql } from "__generated__";
import { StyleSheet, View, useColors } from "components/common";

const ListItemTrailing_AdFragment = gql(`
  fragment ListItemTrailing_AdFragment on Ad {
    id
  }
`);

type ListItemTrailingProps = {
  ad?: FragmentType<typeof ListItemTrailing_AdFragment> | null;
};

export const AdListItemTrailing = (props: ListItemTrailingProps) => {
  const ad = getFragmentData(ListItemTrailing_AdFragment, props?.ad);
  const colors = useColors();

  // eslint-disable-next-line no-unused-expressions
  ad;
  // eslint-disable-next-line no-unused-expressions
  colors;

  // if (!ad?.attachments) return null;

  return (
    <View style={styles.container}>
      {/* {ad?.attachments.map((asset) => (
        <Image
          key={asset.public_id}
          style={[styles.image, { backgroundColor: colors.background }]}
          source={{
            thumbhash: asset.thumbhash,
            uri: cloudinary
              .image(asset?.public_id)
              .resize(Resize.auto(36 * 2, 36 * 2).gravity(autoGravity()))
              .toURL(),
          }}
        />
      ))} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 4,
    flexDirection: "row",
  },
  image: {
    width: 36,
    height: 36,
    borderRadius: 4,
  },
});
