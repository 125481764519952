import { useSubscription } from "@apollo/client";
import { gql } from "__generated__";

const Device_Subscription = gql(`
  subscription Device_Subscription($id: String!) {
    device(id: $id) {
      id
      updatedAt
      online
      wsIds
      appId
      location {
        coords {
          latitude
          longitude
          altitude
          accuracy
          altitudeAccuracy
          heading
          speed
        }
        timestamp
        mocked
      }
    }
  }
`);

export const useDeviceSubscription = (id?: string) => {
  useSubscription(Device_Subscription, {
    variables: { id: id! },
    skip: !id,
  });
};
