import { SectionListMenu } from "components/common";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FragmentType, gql, getFragmentData } from "__generated__";
import moment from "moment";
import { DeviceMap } from "./DeviceMap";

const Device_QueryFragment = gql(`
  fragment Device_QueryFragment on Query {
    device(id: $id) {
      ...DeviceMap_QueryFragment
      id
      appId
      appLocale
      appVersion
      createdAt
      updatedAt
      languageTags
      lastIp
      platform
      timezone
      online
      location {
        coords {
          latitude
          longitude
          altitude
          accuracy
          altitudeAccuracy
          heading
          speed
        }
        timestamp
        mocked
      }
    }
  }
`);

type DeviceProps = {
  query?: FragmentType<typeof Device_QueryFragment>;
};

export const Device = (props: DeviceProps) => {
  const query = getFragmentData(Device_QueryFragment, props.query);

  const { t } = useTranslation("Device");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "info",
        data: [
          {
            key: "id",
            label: t("id"),
            title: query?.device?.id,
            loading: !query,
          },
          {
            key: "online",
            label: t("wasOnlineAt"),
            title: query?.device?.online ? "Online" : "Offline",
            loading: !query,
          },
          {
            key: "appId",
            label: t("appId"),
            title: query?.device?.appId,
            loading: !query,
          },
          {
            key: "appVersion",
            label: t("appVersion"),
            title: query?.device?.appVersion,
            loading: !query,
          },
          {
            key: "platform",
            label: t("platform"),
            title: query?.device?.platform,
            loading: !query,
          },
          {
            key: "lastIp",
            label: t("lastIp"),
            title: query?.device?.lastIp || "unknown",
            loading: !query,
          },
          {
            key: "appLocale",
            label: t("appLocale"),
            title: query?.device?.appLocale,
            loading: !query,
          },
          {
            key: "languageTags",
            label: t("languageTags"),
            title: query?.device?.languageTags.join(", "),
            loading: !query,
          },
          {
            key: "createdAt",
            label: t("createdAt"),
            title: moment(query?.device?.createdAt).format("llll"),
            loading: !query,
          },
          {
            key: "updatedAt",
            label: t("updatedAt"),
            title: query?.device?.updatedAt,
            loading: !query,
          },
          {
            key: "timezone",
            label: t("timezone"),
            title: query?.device?.timezone || "unknown",
            loading: !query,
          },
        ],
      },
      {
        key: "location",
        title:
          t("location.title") +
          ` (${
            query?.device?.location?.mocked
              ? t("location.mocked")
              : t("location.notMocked")
          })`,
        data: [
          {
            key: "timestamp",
            label: t("location.timestamp"),
            title: query?.device?.location?.timestamp,
            loading: !query,
          },
          {
            key: "latitude",
            label: t("location.latitude"),
            title: query?.device?.location?.coords.latitude,
            loading: !query,
          },
          {
            key: "longitude",
            label: t("location.longitude"),
            title: query?.device?.location?.coords.longitude,
            loading: !query,
          },
          {
            key: "altitude",
            label: t("location.altitude"),
            title: query?.device?.location?.coords.altitude,
            loading: !query,
          },
          {
            key: "accuracy",
            label: t("location.accuracy"),
            title: query?.device?.location?.coords.accuracy,
            loading: !query,
          },
          {
            key: "altitudeAccuracy",
            label: t("location.altitudeAccuracy"),
            title: query?.device?.location?.coords.altitudeAccuracy,
            loading: !query,
          },
          {
            key: "heading",
            label: t("location.heading"),
            title: query?.device?.location?.coords.heading,
            loading: !query,
          },
          {
            key: "speed",
            label: t("location.speed"),
            title: query?.device?.location?.coords.speed,
            loading: !query,
          },
        ],
      },
      {
        key: "map",
        data: [
          {
            key: "map",
            Component: <DeviceMap device={query?.device} />,
          },
        ],
      },
    ];
  }, [query, t]);

  return <SectionListMenu sections={sections} />;
};
