import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Report } from "components/Report/Report";
import { useLocalSearchParams } from "expo-router";
import { useEffect } from "react";

const Report_Query = gql(`
  query Report_Query($id: ObjectID!) {
    ...Report_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data, refetch } = useQuery(Report_Query, { variables: { id } });

  useEffect(() => {
    const tmr = setInterval(() => {
      refetch();
    }, 1000);
    return () => clearInterval(tmr);
  }, [refetch]);

  return <Report query={data} />;
}
