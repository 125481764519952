import { SectionListMenu } from "components/common";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { AppHeader } from "./AppHeader";
import { useMutation } from "@apollo/client";

const AppSetActive_Mutatuion = gql(`
  mutation AppSetActive_Mutatuion($id: ObjectID!, $active: Boolean!) {
    appSetActive(id: $id, active: $active) {
      id
      active
    }
  }
`);

const App_QueryFragment = gql(`
  fragment App_QueryFragment on Query {
    app(id: $id) {
      id
      label
      package
      versionName
      versionCode
      active
      ...Header_AppFragment
    }
  }
`);

type UserProps = {
  query?: FragmentType<typeof App_QueryFragment>;
};

export const App = (props: UserProps) => {
  const query = getFragmentData(App_QueryFragment, props.query);

  const { t } = useTranslation("App");

  const [setAppActive, { loading }] = useMutation(AppSetActive_Mutatuion);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "info",
        data: [
          {
            key: "label",
            label: t("label"),
            title: query?.app?.label,
            loading: !query,
          },
          {
            key: "package",
            label: t("package"),
            title: query?.app?.package,
            loading: !query,
          },
          {
            key: "versionName",
            label: t("versionName"),
            title: query?.app?.versionName,
            loading: !query,
          },
          {
            key: "versionCode",
            label: t("versionCode"),
            title: query?.app?.versionCode,
            loading: !query,
          },
        ],
      },
      {
        key: "active",
        visible: !!query,
        data: [
          {
            key: "active",
            title: t(query?.app?.active ? "deactivate" : "makeActive"),
            centerContent: true,
            buttonType: query?.app?.active ? "destructive" : "primary",
            loading,
            onPress: () => {
              if (!query?.app) return;

              setAppActive({
                variables: {
                  id: query.app.id,
                  active: !query.app.active,
                },
              });
            },
          },
        ],
      },
    ];
  }, [loading, query, setAppActive, t]);

  return (
    <SectionListMenu
      sections={sections}
      ListHeaderComponent={<AppHeader app={query?.app} />}
    />
  );
};
