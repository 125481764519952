import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CarCreate } from "components/Car/CarCreate";
import { useDraft } from "components/drafts/drafts";
import { useLocalSearchParams } from "expo-router";
import { useEffect } from "react";

export const CarCreate_Query = gql(`
  query CarCreate_Query($orgId: ObjectID, $driverIds: [ObjectID!], $hideCurrentOrgMemberRole: Boolean = true) {
    ...CarCreate_QueryFragment
  }
`);

export default function Page() {
  const { orgId } = useLocalSearchParams<{ orgId?: string }>();
  const [draft, setDraft] = useDraft("carDraft");

  useEffect(() => {
    orgId && setDraft({ orgId });
  }, [orgId, setDraft]);

  const { data } = useQuery(CarCreate_Query, {
    variables: {
      orgId: draft.orgId,
      driverIds: draft.driverIds,
    },
  });

  return <CarCreate query={data} />;
}
