import AppInfoParser from "app-info-parser/src/apk";
import { DocumentPickerAsset } from "expo-document-picker";
import { ImagePickerAsset } from "expo-image-picker";

export const getApkInfo = (asset: ImagePickerAsset | DocumentPickerAsset) => {
  if (asset.mimeType !== "application/vnd.android.package-archive")
    throw new Error("Invalid file type");

  const parser = new AppInfoParser(("file" in asset && asset.file) as File);

  return parser.parse().then((res) => {
    return {
      label: res.application?.label?.[0] || "",
      package: res.package,
      versionName: res.versionName,
      versionCode: res.versionCode,
      icon: res.icon,
    };
  });
};
