/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Ad_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...Ad_QueryFragment\n  }\n": types.Ad_QueryDocument,
    "\n  query AdCreate_Query($orgId: ObjectID, $campaignId: ObjectID, $carId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...AdCreate_QueryFragment\n  }\n": types.AdCreate_QueryDocument,
    "\n  query App_Query($id: ObjectID!) {\n    ...App_QueryFragment\n  }\n": types.App_QueryDocument,
    "\n  query Ads_Query($id: ObjectID!) {\n    ...AdList2_QueryFragment\n    campaign(id: $id) {\n      id\n      org {\n        id\n      }\n    }\n  }\n": types.Ads_QueryDocument,
    "\n  query CampaignEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignEdit_QueryFragment\n  }\n": types.CampaignEdit_QueryDocument,
    "\n  query Campaign_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Campaign_QueryFragment\n  }\n": types.Campaign_QueryDocument,
    "\n  query CampaignCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignCreate_QueryFragment\n  }\n": types.CampaignCreate_QueryDocument,
    "\n  query CampaignPicker_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n": types.CampaignPicker_QueryDocument,
    "\n  query Car_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Car_QueryFragment\n  }\n": types.Car_QueryDocument,
    "\n  query CarCreate_Query($orgId: ObjectID, $driverIds: [ObjectID!], $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CarCreate_QueryFragment\n  }\n": types.CarCreate_QueryDocument,
    "\n  query CarPicker_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n": types.CarPicker_QueryDocument,
    "\n  query Device_Query($id: String) {\n    ...Device_QueryFragment\n  }\n": types.Device_QueryDocument,
    "\n  query MediaAd_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...MediaAd_QueryFragment\n  }\n": types.MediaAd_QueryDocument,
    "\n   query MediaAdCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...MediaAdCreate_QueryFragment\n  }\n": types.MediaAdCreate_QueryDocument,
    "\n  query Org_Query($id: ObjectID!) {\n    org(id: $id) {\n      id\n      member {\n        id\n        role\n      }\n    }\n    ...Org_QueryFragment\n  }\n": types.Org_QueryDocument,
    "\n  query OrgPicker_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n": types.OrgPicker_QueryDocument,
    "\n  query OrgMemberEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMemberEdit_QueryFragment\n  }\n": types.OrgMemberEdit_QueryDocument,
    "\n  query OrgMember_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMember_QueryFragment\n  }\n": types.OrgMember_QueryDocument,
    "\n  query OrgMemberInvite_Query($orgId: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...OrgMemberInvite_QueryFragment\n  }\n": types.OrgMemberInvite_QueryDocument,
    "\n  query OrgMemberPicker_Query($orgId: ObjectID) {\n    ...OrgMemberList_QueryFragment\n  }\n": types.OrgMemberPicker_QueryDocument,
    "\n  query User_Query($id: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...User_QueryFragment\n  }\n": types.User_QueryDocument,
    "\n  query Tabs_Query {\n    user {\n      orgsMember {\n        id\n        orgId\n        inviteStatus\n        permissions\n      }\n    }\n  }\n": types.Tabs_QueryDocument,
    "\n  query Campaigns_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CampaignList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n": types.Campaigns_QueryDocument,
    "\n  query Cars_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CarList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n": types.Cars_QueryDocument,
    "\n  query ControlAds_Query {\n    ...AdList_QueryFragment\n  }\n": types.ControlAds_QueryDocument,
    "\n  query ControlApps_Query {\n    ...AppList_QueryFragment\n  }\n": types.ControlApps_QueryDocument,
    "\n  query ControlAdCmpaigns_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n": types.ControlAdCmpaigns_QueryDocument,
    "\n  query ControlCars_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n": types.ControlCars_QueryDocument,
    "\n  query ControlDevices_Query {\n    ...DeviceList_QueryFragment\n  }\n": types.ControlDevices_QueryDocument,
    "\n  query ControlMediaAds_Query {\n    ...MediaAdList_QueryFragment\n  }\n": types.ControlMediaAds_QueryDocument,
    "\n  query ControlOrgs_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n": types.ControlOrgs_QueryDocument,
    "\n  query ControlSettings_Query {\n    ...ControlSettings_QueryFragment\n  }\n": types.ControlSettings_QueryDocument,
    "\n  query ControlUsers_Query {\n    ...UserList_QueryFragment\n  }\n": types.ControlUsers_QueryDocument,
    "\n  query OrgMembers_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgMemberList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n": types.OrgMembers_QueryDocument,
    "\n  query Settings_Query($hideCurrentOrgMemberRole: Boolean = false) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgSwitcher_QueryFragment\n    ...Settings_QueryFragment\n  }\n": types.Settings_QueryDocument,
    "\n  query ProfileEdit_Query {\n    ...ProfileEdit_QueryFragment\n  }\n": types.ProfileEdit_QueryDocument,
    "\n  query SignUp_Query {\n    ...SignUp_QueryFragment\n  }\n": types.SignUp_QueryDocument,
    "\n  mutation SendTestSms_Mutation($flash: Boolean) {\n    sendTestSms(flash: $flash)\n  }\n": types.SendTestSms_MutationDocument,
    "\n  mutation SendTestPush_Mutation {\n    sendTestPush\n  }\n": types.SendTestPush_MutationDocument,
    "\n  query Report_Query($id: ObjectID!) {\n    ...Report_QueryFragment\n  }\n": types.Report_QueryDocument,
    "\n  fragment Ad_QueryFragment on Query {\n    ad(id: $id) {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n        ...GetListItem_CampaignFragment\n        org {\n          ...GetListItem_OrgFragment\n        }\n      }\n      car {\n        ...GetListItem_CarFragment\n      }\n    }\n  }\n": types.Ad_QueryFragmentFragmentDoc,
    "\n  mutation AddCreate_Mutation(\n    $campaignId: ObjectID!\n    $data: AdCreateInput!\n  ) {\n    addAd(campaignId: $campaignId, data: $data) {\n      id\n    }\n  }\n": types.AddCreate_MutationDocument,
    "\n  fragment AdCreate_QueryFragment on Query {\n    campaign(id: $campaignId) {\n      id\n      ...GetListItem_CampaignFragment\n    }\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    car(id: $carId, orgId: $orgId) {\n      id\n      ...GetListItem_CarFragment\n    }\n  }\n": types.AdCreate_QueryFragmentFragmentDoc,
    "\n  fragment AdList_QueryFragment on Query {\n    ads {\n      ...GetListItem_AdFragment\n    }\n  }\n": types.AdList_QueryFragmentFragmentDoc,
    "\n  fragment AdList2_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        org {\n            id\n            name\n        }\n        ads {\n          ...GetListItem_AdFragment\n        }\n    }\n  }\n": types.AdList2_QueryFragmentFragmentDoc,
    "\n  fragment ListItemTrailing_AdFragment on Ad {\n    id\n  }\n": types.ListItemTrailing_AdFragmentFragmentDoc,
    "\n  fragment GetListItem_AdFragment on Ad {\n    id\n    createdAt\n    car {\n        id\n        brand\n        model\n        year\n        plate\n    }\n    campaign {\n        id\n        org {\n            id\n            name\n        }\n        periodDays\n        name\n    }\n    ...ListItemTrailing_AdFragment\n  }\n": types.GetListItem_AdFragmentFragmentDoc,
    "\n  mutation AppSetActive_Mutatuion($id: ObjectID!, $active: Boolean!) {\n    appSetActive(id: $id, active: $active) {\n      id\n      active\n    }\n  }\n": types.AppSetActive_MutatuionDocument,
    "\n  fragment App_QueryFragment on Query {\n    app(id: $id) {\n      id\n      label\n      package\n      versionName\n      versionCode\n      active\n      ...Header_AppFragment\n    }\n  }\n": types.App_QueryFragmentFragmentDoc,
    "\n  mutation AppCreate_Mutatuion($data: AppCreateInput!) {\n    appCreate(data: $data) {\n      id\n      active\n    }\n  }\n": types.AppCreate_MutatuionDocument,
    "\n  fragment Header_AppFragment on App {\n    id\n    ...Icon_AppFragment\n  }\n": types.Header_AppFragmentFragmentDoc,
    "\n  fragment Icon_AppFragment on App {\n    id\n    icon\n  }\n": types.Icon_AppFragmentFragmentDoc,
    "\n  fragment AppList_QueryFragment on Query {\n    apps {\n      ...GetListItem_AppFragment\n    }\n  }\n": types.AppList_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_AppFragment on App {\n    id\n    label\n    package\n    versionName\n    versionCode\n    active\n    ...Icon_AppFragment\n  }\n": types.GetListItem_AppFragmentFragmentDoc,
    "\n  fragment Campaign_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n    }\n  }\n": types.Campaign_QueryFragmentFragmentDoc,
    "\n  mutation AddCampaign_Mutation($orgId: ObjectID!, $data: CampaignCreateInput!) {\n    addCampaign(orgId: $orgId, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n": types.AddCampaign_MutationDocument,
    "\n  fragment CampaignCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n": types.CampaignCreate_QueryFragmentFragmentDoc,
    "\n  mutation SaveCampaign_Mutation($id: ObjectID!, $data: CampaignSaveInput!) {\n    saveCampaign(id: $id, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n": types.SaveCampaign_MutationDocument,
    "\n  fragment CampaignEdit_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        name\n        ...GetListItem_OrgFragment\n      }\n    }\n    user {\n      orgsMember {\n        id\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CampaignEdit_QueryFragmentFragmentDoc,
    "\n  fragment CampaignList_QueryFragment on Query {\n    campaigns(orgId: $orgId) {\n      ...GetListItem_CampaignFragment\n    }\n  }\n": types.CampaignList_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_CampaignFragment on Campaign {\n    id\n    name\n    periodDays\n    carsCount\n    org {\n        id\n        name\n    }\n  }\n": types.GetListItem_CampaignFragmentFragmentDoc,
    "\n  fragment Car_QueryFragment on Query {\n    car(id: $id) {\n      id\n      year\n      plate\n      drivers {\n        id\n        ...GetListItem_OrgMemberFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      currentAd {\n        id\n        ...GetListItem_AdFragment\n      }\n      ads {\n        id\n        ...GetListItem_AdFragment\n      }\n      ...Header_CarFragment\n    }\n  }\n": types.Car_QueryFragmentFragmentDoc,
    "\n  mutation AddCar_Mutation($orgId: ObjectID!, $data: CarCreateInput!) {\n    addCar(orgId: $orgId, data: $data) {\n      id\n    }\n  }\n": types.AddCar_MutationDocument,
    "\n  fragment CarCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    orgMembers(ids: $driverIds, orgId: $orgId) {\n      id\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n": types.CarCreate_QueryFragmentFragmentDoc,
    "\n  mutation AddCarDriver_Mutation($carId: ObjectID!, $phone: String!) {\n    addCarDriver(carId: $carId, phone: $phone) {\n      id\n    }\n  }\n": types.AddCarDriver_MutationDocument,
    "\n  mutation DeleteCar_Mutation($id: ObjectID!) {\n    deleteCar(id: $id)\n  }\n": types.DeleteCar_MutationDocument,
    "\n  fragment Header_CarFragment on Car {\n    id\n    brand\n    model\n    plate\n  }\n": types.Header_CarFragmentFragmentDoc,
    "\n  fragment CarList_QueryFragment on Query {\n    cars(orgId: $orgId) {\n      id\n      currentAd {\n        id\n      }\n      ...GetListItem_CarFragment\n    }\n  }\n": types.CarList_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_CarFragment on Car {\n    id\n    brand\n    model\n    year\n    plate\n    org {\n        id\n        name\n    }\n    drivers {\n        id\n    }\n    currentAd {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n      \n      }\n    }\n  }\n": types.GetListItem_CarFragmentFragmentDoc,
    "\n  mutation RemoveCarDriver_Mutation($carId: ObjectID!, $memberId: ObjectID!) {\n    removeCarDriver(carId: $carId, memberId: $memberId) {\n      id\n      drivers {\n        id\n      }\n    }\n  }\n": types.RemoveCarDriver_MutationDocument,
    "\n  mutation ControlSettings_Mutation($data: SettingsSaveInput!) {\n    saveSettings(data: $data) {\n      ...ControlSettings_Fragment\n    }\n  }\n": types.ControlSettings_MutationDocument,
    "\n  fragment ControlSettings_Fragment on AppSettings {\n    id\n    whitelistEnabled\n    whitelist\n  }\n": types.ControlSettings_FragmentFragmentDoc,
    "\n  fragment ControlSettings_QueryFragment on Query {\n    settings {\n      ...ControlSettings_Fragment\n    }\n  }\n": types.ControlSettings_QueryFragmentFragmentDoc,
    "\n  fragment Device_QueryFragment on Query {\n    device(id: $id) {\n      ...DeviceMap_QueryFragment\n      id\n      appId\n      appLocale\n      appVersion\n      createdAt\n      updatedAt\n      languageTags\n      lastIp\n      platform\n      timezone\n      online\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n": types.Device_QueryFragmentFragmentDoc,
    "\n  fragment DeviceList_QueryFragment on Query {\n    devices {\n      ...GetListItem_DeviceFragment\n    }\n  }\n": types.DeviceList_QueryFragmentFragmentDoc,
    "\n  fragment DeviceMap_QueryFragment on Device {\n    id\n    location {\n      coords {\n        latitude\n        longitude\n        altitude\n        accuracy\n        altitudeAccuracy\n        heading\n        speed\n      }\n    }\n  }\n": types.DeviceMap_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_DeviceFragment on Device {\n    id\n    appId\n    platform\n    appVersion\n    online\n  }\n": types.GetListItem_DeviceFragmentFragmentDoc,
    "\n  mutation SendContact_Mutation($name: String!, $email: String!, $phone: String!) {\n    sendContact(name: $name, email: $email, phone: $phone)\n  }\n": types.SendContact_MutationDocument,
    "\n  mutation MediaAdSetEnabled_Mutatuion($id: ObjectID!, $enabled: Boolean!) {\n    mediaAdSetEnabled(id: $id, enabled: $enabled) {\n      id\n      enabled\n    }\n  }\n": types.MediaAdSetEnabled_MutatuionDocument,
    "\n  fragment MediaAd_QueryFragment on Query {\n    mediaAd(id: $id) {\n      id\n      name\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      upload {\n        ...UploadFragment\n      }\n      enabled\n      impressionsCount\n      viewsCount\n    }\n  }\n": types.MediaAd_QueryFragmentFragmentDoc,
    "\n  mutation MediaAdCreate_Mutation(\n    $data: MediaAdCreateInput!\n  ) {\n    mediaAdCreate(data: $data) {\n      id\n    }\n  }\n": types.MediaAdCreate_MutationDocument,
    "\n  fragment MediaAdCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n": types.MediaAdCreate_QueryFragmentFragmentDoc,
    "\n  fragment MediaAdList_QueryFragment on Query {\n    mediaAds {\n      ...GetListItem_MediaAdFragment\n    }\n  }\n": types.MediaAdList_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_MediaAdFragment on MediaAd {\n    id\n    name\n    org {\n      id\n      name\n    }\n    enabled\n    impressionsCount\n    viewsCount\n  }\n": types.GetListItem_MediaAdFragmentFragmentDoc,
    "\n  fragment Org_QueryFragment on Query {\n    org(id: $id) {\n      id\n      name\n      tin\n      entityTypeName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n      member {\n        id\n        role\n        inviteStatus\n      }\n      ...Header_OrgFragment\n    }\n  }\n": types.Org_QueryFragmentFragmentDoc,
    "\n  query OrgInfo_Query($tin: Int!) {\n    orgInfo(tin: $tin) {\n      entityTypeName\n      displayName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n    }\n  }\n": types.OrgInfo_QueryDocument,
    "\n  mutation AddOrg_Mutation($tin: Int!, $jobTitle: String!) {\n    addOrg(tin: $tin, jobTitle: $jobTitle) {\n      id\n      org {\n        id\n      }\n    }\n  }\n": types.AddOrg_MutationDocument,
    "\n  fragment Header_OrgFragment on Org {\n    id\n    name\n    members {\n      id\n    }\n    member {\n      id\n      role\n    }\n  }\n": types.Header_OrgFragmentFragmentDoc,
    "\n  fragment OrgList_QueryFragment on Query {\n    orgs(member: $member) {\n      ...GetListItem_OrgFragment\n    }\n  }\n": types.OrgList_QueryFragmentFragmentDoc,
    "\n  fragment ListItemFooter_OrgFragment on Org {\n    id\n    name\n    member {\n        id\n        inviteStatus\n        inviter {\n            id\n            firstName\n            lastName\n        }\n    }\n  }\n": types.ListItemFooter_OrgFragmentFragmentDoc,
    "\n  mutation DeleteOrg_Mutation($id: ObjectID!) {\n    deleteOrg(id: $id)\n  }\n": types.DeleteOrg_MutationDocument,
    "\n  fragment OrgSwitcher_QueryFragment on Query {\n    user {\n      id\n      orgsMember {\n        id\n        jobTitle\n        role\n        inviteStatus\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.OrgSwitcher_QueryFragmentFragmentDoc,
    "\n  fragment GetListItem_OrgFragment on Org {\n    id\n    name\n    tin\n    member @skip(if: $hideCurrentOrgMemberRole) {\n        id\n        jobTitle\n        role\n    }\n    ...ListItemFooter_OrgFragment\n  }\n": types.GetListItem_OrgFragmentFragmentDoc,
    "\n  mutation RejectOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    rejectOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n": types.RejectOrgMemberInvite_MutationDocument,
    "\n  mutation AcceptOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    acceptOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n": types.AcceptOrgMemberInvite_MutationDocument,
    "\n  fragment OrgMember_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        phone\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n  }\n": types.OrgMember_QueryFragmentFragmentDoc,
    "\n  mutation ResendOrgMemberInvite_Mutation($id: ObjectID!) {\n    resendOrgMemberInvite(id: $id) {\n      id\n      inviteStatus\n    }\n  }\n": types.ResendOrgMemberInvite_MutationDocument,
    "\n  mutation TransferOrg_Mutation($orgId: ObjectID!, $memberId: ObjectID!) {\n    trasferOrg(orgId: $orgId, memberId: $memberId) {\n      id\n      role\n      permissions\n    }\n  }\n": types.TransferOrg_MutationDocument,
    "\n  mutation SaveOrgMember_Mutation(\n    $id: ObjectID!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    saveOrgMember(id: $id, jobTitle: $jobTitle, permissions: $permissions) {\n      id\n      jobTitle\n      permissions\n    }\n  }\n": types.SaveOrgMember_MutationDocument,
    "\n  fragment OrgMemberEdit_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        firstName\n        lastName\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        name\n        member {\n          id\n          permissions\n          role\n        }\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n    user {\n      id\n    }\n  }\n": types.OrgMemberEdit_QueryFragmentFragmentDoc,
    "\n  mutation InviteOrgMember_Mutation(\n    $orgId: ObjectID!\n    $phone: String!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    inviteOrgMember(\n      orgId: $orgId\n      phone: $phone\n      jobTitle: $jobTitle\n      permissions: $permissions\n    ) {\n      id\n      jobTitle\n      user {\n        id\n        phone\n      }\n    }\n  }\n": types.InviteOrgMember_MutationDocument,
    "\n  fragment OrgMemberInvite_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n      ...GetAdminFeaturesSection_OrgMemberFragment\n    }\n  }\n": types.OrgMemberInvite_QueryFragmentFragmentDoc,
    "\n  fragment OrgMemberList_QueryFragment on Query {\n    orgMembers(orgId: $orgId) {\n      id\n      role\n      orgId\n      inviteStatus\n      permissions\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n": types.OrgMemberList_QueryFragmentFragmentDoc,
    "\n  fragment GetAdminFeaturesSection_OrgMemberFragment on Org {\n    member {\n        id\n        permissions\n    }\n  }\n": types.GetAdminFeaturesSection_OrgMemberFragmentFragmentDoc,
    "\n  fragment GetListItem_OrgMemberFragment on OrgMember {\n    id\n    inviteStatus\n    role\n    jobTitle\n    user {\n        firstName\n        lastName\n        phone\n        ...Photo_UserFragment\n    }\n    inviter {\n        id\n        firstName\n    }\n  }\n": types.GetListItem_OrgMemberFragmentFragmentDoc,
    "\n  mutation RemoveOrgMember_Mutation($id: ObjectID!) {\n    removeOrgMember(id: $id)\n  }\n": types.RemoveOrgMember_MutationDocument,
    "\n  fragment AssetFragment on Upload {\n   id\n  }\n": types.AssetFragmentFragmentDoc,
    "\n  mutation ProfileEdit_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n": types.ProfileEdit_MutationDocument,
    "\n  fragment ProfileEdit_QueryFragment on Query {\n    user {\n      id\n      phone\n      firstName\n      middleName\n      lastName\n      ...ProfileEditHeader_UserFragment\n    }\n  }\n": types.ProfileEdit_QueryFragmentFragmentDoc,
    "\n  fragment ProfileEditHeader_UserFragment on User {\n    ...Photo_UserFragment\n  }\n": types.ProfileEditHeader_UserFragmentFragmentDoc,
    "\n  fragment Report_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        periodDays\n        carsCount\n        regions\n        status\n        plannedAt\n        startedAt\n        org {\n            id\n            name\n        }\n        ads {\n            id\n            createdAt\n            car {\n                id\n                plate\n                brand\n                model\n                coordinates\n            }\n        }\n        ...ReportMap_QueryFragment\n    }\n  }\n": types.Report_QueryFragmentFragmentDoc,
    "\n  fragment ReportMap_QueryFragment on Campaign {\n    ads {\n        id\n        car {\n            id\n            coordinates\n        }\n    }\n  }\n": types.ReportMap_QueryFragmentFragmentDoc,
    "\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      ...Header_SettingsFragment\n      orgsMember {\n        id\n        inviteStatus\n        org {\n          id\n          ...GetListItem_OrgFragment\n        }\n      }\n    }\n  }\n": types.Settings_QueryFragmentFragmentDoc,
    "\n  fragment Header_SettingsFragment on User {\n    id\n    firstName\n    lastName\n    ...Photo_UserFragment\n  }\n": types.Header_SettingsFragmentFragmentDoc,
    "\n  mutation SendCode_Mutation($deviceId: String!, $phone: String!) {\n    sendCode(deviceId: $deviceId, phone: $phone) {\n      type\n      codeLength\n      code\n    }\n  }\n": types.SendCode_MutationDocument,
    "\n  mutation SignIn_Mutation($deviceId: String!, $phone: String!, $code: String!) {\n    signIn(deviceId: $deviceId, phone: $phone, code: $code) {\n      token\n      user {\n        id\n        signUpRequired\n      }\n    }\n  }\n": types.SignIn_MutationDocument,
    "\n  fragment SignUp_QueryFragment on Query {\n    user {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n": types.SignUp_QueryFragmentFragmentDoc,
    "\n  mutation SignUp_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n": types.SignUp_MutationDocument,
    "\n  fragment UploadFragment on Upload {\n    id\n    fileName\n    mime\n    signedUrl\n    cloudType\n    thumbhash\n    url\n    previewUrl: url(size: 200)\n    localUri @client\n    progress @client {\n      progress\n    }\n    app @client {\n      label\n      package\n      versionName\n      versionCode\n      icon\n    }\n    ...UploadProgressFragment\n  }\n": types.UploadFragmentFragmentDoc,
    "\n  mutation UploadCreate_Mutation($data: [UploadCreateInput!]!) {\n    uploadsCreate(data: $data) {\n      ...UploadFragment\n    }\n  }\n": types.UploadCreate_MutationDocument,
    "\n  mutation UploadUpdate_Mutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      ...UploadFragment\n    }\n  }\n": types.UploadUpdate_MutationDocument,
    "\n  fragment UploadProgressFragment on Upload {\n    id\n    progress @client {\n      progress\n    }\n  }\n": types.UploadProgressFragmentFragmentDoc,
    "\n  fragment User_QueryFragment on Query {\n    user(id: $id) {\n      id\n      phone\n      inviter {\n        id\n        firstName\n        lastName\n      }\n      orgsMember {\n        id\n        org {\n          ...GetListItem_OrgFragment\n        }\n        cars {\n          ...GetListItem_CarFragment\n        }\n      }\n      ...Header_UserFragment\n    }\n  }\n": types.User_QueryFragmentFragmentDoc,
    "\n  fragment Header_UserFragment on User {\n    firstName\n    middleName\n    lastName\n    phone\n    signUpRequired\n    ...Photo_UserFragment\n  }\n": types.Header_UserFragmentFragmentDoc,
    "\n  mutation UserInvite_Mutation(\n    $phone: String!\n  ) {\n    inviteUser(phone: $phone) {\n      id,\n      phone\n    }\n  }\n": types.UserInvite_MutationDocument,
    "\n  fragment UserList_QueryFragment on Query {\n    users {\n      ...GetListItem_UserFragment\n    }\n  }\n": types.UserList_QueryFragmentFragmentDoc,
    "\n  fragment Photo_UserFragment on User {\n    id\n  }\n": types.Photo_UserFragmentFragmentDoc,
    "\n  fragment GetListItem_UserFragment on User {\n    id\n    phone\n    firstName\n    lastName\n    signUpRequired\n    inviter {\n      id\n      firstName\n      lastName\n    }\n    ...Photo_UserFragment\n  }\n": types.GetListItem_UserFragmentFragmentDoc,
    "\n  query AdDraft_Query {\n    adDraft @client {\n      orgId\n      campaignId\n      carId\n      upload {\n        ...UploadFragment\n      }\n    }\n  }\n": types.AdDraft_QueryDocument,
    "\n  query AppDraft_Query {\n    appDraft @client {\n      upload {\n        id\n        app {\n          label\n          package\n          versionName\n          versionCode\n          icon\n        }\n        ...UploadFragment\n      }\n      active @client\n    }\n  }\n": types.AppDraft_QueryDocument,
    "\n  query CampaignDraft_Query {\n    campaignDraft @client {\n      orgId\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n      uploads {\n        ...UploadFragment\n      }\n    }\n  }\n": types.CampaignDraft_QueryDocument,
    "\n  query CarDraft_Query {\n    carDraft @client {\n      orgId\n      plate\n      brand\n      model\n      year\n      driverIds\n    }\n  }\n": types.CarDraft_QueryDocument,
    "\n  query MediaAdDraft_Query {\n    mediaAdDraft @client {\n      orgId\n      name\n      enabled\n      upload {\n        id\n        ...UploadFragment\n      }\n    }\n  }\n": types.MediaAdDraft_QueryDocument,
    "\n  subscription Device_Subscription($id: String!) {\n    device(id: $id) {\n      id\n      updatedAt\n      online\n      wsIds\n      appId\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n": types.Device_SubscriptionDocument,
    "\n  subscription Online_Subscription {\n    online\n  }\n": types.Online_SubscriptionDocument,
    "\n  mutation SignOut_Mutation($token: String!) {\n    signOut(token: $token)\n  }\n": types.SignOut_MutationDocument,
    "\n  mutation UseUpdateCurrentUserPhoto_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n": types.UseUpdateCurrentUserPhoto_MutationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Ad_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...Ad_QueryFragment\n  }\n"): (typeof documents)["\n  query Ad_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...Ad_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdCreate_Query($orgId: ObjectID, $campaignId: ObjectID, $carId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...AdCreate_QueryFragment\n  }\n"): (typeof documents)["\n  query AdCreate_Query($orgId: ObjectID, $campaignId: ObjectID, $carId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...AdCreate_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query App_Query($id: ObjectID!) {\n    ...App_QueryFragment\n  }\n"): (typeof documents)["\n  query App_Query($id: ObjectID!) {\n    ...App_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Ads_Query($id: ObjectID!) {\n    ...AdList2_QueryFragment\n    campaign(id: $id) {\n      id\n      org {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query Ads_Query($id: ObjectID!) {\n    ...AdList2_QueryFragment\n    campaign(id: $id) {\n      id\n      org {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CampaignEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignEdit_QueryFragment\n  }\n"): (typeof documents)["\n  query CampaignEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignEdit_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Campaign_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Campaign_QueryFragment\n  }\n"): (typeof documents)["\n  query Campaign_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Campaign_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CampaignCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignCreate_QueryFragment\n  }\n"): (typeof documents)["\n  query CampaignCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CampaignCreate_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CampaignPicker_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n"): (typeof documents)["\n  query CampaignPicker_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Car_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Car_QueryFragment\n  }\n"): (typeof documents)["\n  query Car_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...Car_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CarCreate_Query($orgId: ObjectID, $driverIds: [ObjectID!], $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CarCreate_QueryFragment\n  }\n"): (typeof documents)["\n  query CarCreate_Query($orgId: ObjectID, $driverIds: [ObjectID!], $hideCurrentOrgMemberRole: Boolean = true) {\n    ...CarCreate_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CarPicker_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n"): (typeof documents)["\n  query CarPicker_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Device_Query($id: String) {\n    ...Device_QueryFragment\n  }\n"): (typeof documents)["\n  query Device_Query($id: String) {\n    ...Device_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MediaAd_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...MediaAd_QueryFragment\n  }\n"): (typeof documents)["\n  query MediaAd_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean! = true) {\n    ...MediaAd_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query MediaAdCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...MediaAdCreate_QueryFragment\n  }\n"): (typeof documents)["\n   query MediaAdCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...MediaAdCreate_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Org_Query($id: ObjectID!) {\n    org(id: $id) {\n      id\n      member {\n        id\n        role\n      }\n    }\n    ...Org_QueryFragment\n  }\n"): (typeof documents)["\n  query Org_Query($id: ObjectID!) {\n    org(id: $id) {\n      id\n      member {\n        id\n        role\n      }\n    }\n    ...Org_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgPicker_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgPicker_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgMemberEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMemberEdit_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgMemberEdit_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMemberEdit_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgMember_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMember_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgMember_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    orgMember(id: $id) {\n      id\n      role\n      inviteStatus\n    }\n    ...OrgMember_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgMemberInvite_Query($orgId: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...OrgMemberInvite_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgMemberInvite_Query($orgId: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...OrgMemberInvite_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgMemberPicker_Query($orgId: ObjectID) {\n    ...OrgMemberList_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgMemberPicker_Query($orgId: ObjectID) {\n    ...OrgMemberList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query User_Query($id: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...User_QueryFragment\n  }\n"): (typeof documents)["\n  query User_Query($id: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {\n    ...User_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Tabs_Query {\n    user {\n      orgsMember {\n        id\n        orgId\n        inviteStatus\n        permissions\n      }\n    }\n  }\n"): (typeof documents)["\n  query Tabs_Query {\n    user {\n      orgsMember {\n        id\n        orgId\n        inviteStatus\n        permissions\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Campaigns_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CampaignList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"): (typeof documents)["\n  query Campaigns_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CampaignList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Cars_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CarList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"): (typeof documents)["\n  query Cars_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...CarList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlAds_Query {\n    ...AdList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlAds_Query {\n    ...AdList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlApps_Query {\n    ...AppList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlApps_Query {\n    ...AppList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlAdCmpaigns_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlAdCmpaigns_Query($orgId: ObjectID) {\n    ...CampaignList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlCars_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlCars_Query($orgId: ObjectID) {\n    ...CarList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlDevices_Query {\n    ...DeviceList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlDevices_Query {\n    ...DeviceList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlMediaAds_Query {\n    ...MediaAdList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlMediaAds_Query {\n    ...MediaAdList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlOrgs_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlOrgs_Query($hideCurrentOrgMemberRole: Boolean = false, $member: Boolean) {\n    ...OrgList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlSettings_Query {\n    ...ControlSettings_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlSettings_Query {\n    ...ControlSettings_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ControlUsers_Query {\n    ...UserList_QueryFragment\n  }\n"): (typeof documents)["\n  query ControlUsers_Query {\n    ...UserList_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgMembers_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgMemberList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"): (typeof documents)["\n  query OrgMembers_Query($orgId: ObjectID!) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgMemberList_QueryFragment\n    ...OrgSwitcher_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Settings_Query($hideCurrentOrgMemberRole: Boolean = false) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgSwitcher_QueryFragment\n    ...Settings_QueryFragment\n  }\n"): (typeof documents)["\n  query Settings_Query($hideCurrentOrgMemberRole: Boolean = false) {\n    user {\n      orgsMember {\n        id\n        inviteStatus\n      }\n    }\n    ...OrgSwitcher_QueryFragment\n    ...Settings_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfileEdit_Query {\n    ...ProfileEdit_QueryFragment\n  }\n"): (typeof documents)["\n  query ProfileEdit_Query {\n    ...ProfileEdit_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SignUp_Query {\n    ...SignUp_QueryFragment\n  }\n"): (typeof documents)["\n  query SignUp_Query {\n    ...SignUp_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendTestSms_Mutation($flash: Boolean) {\n    sendTestSms(flash: $flash)\n  }\n"): (typeof documents)["\n  mutation SendTestSms_Mutation($flash: Boolean) {\n    sendTestSms(flash: $flash)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendTestPush_Mutation {\n    sendTestPush\n  }\n"): (typeof documents)["\n  mutation SendTestPush_Mutation {\n    sendTestPush\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Report_Query($id: ObjectID!) {\n    ...Report_QueryFragment\n  }\n"): (typeof documents)["\n  query Report_Query($id: ObjectID!) {\n    ...Report_QueryFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Ad_QueryFragment on Query {\n    ad(id: $id) {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n        ...GetListItem_CampaignFragment\n        org {\n          ...GetListItem_OrgFragment\n        }\n      }\n      car {\n        ...GetListItem_CarFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Ad_QueryFragment on Query {\n    ad(id: $id) {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n        ...GetListItem_CampaignFragment\n        org {\n          ...GetListItem_OrgFragment\n        }\n      }\n      car {\n        ...GetListItem_CarFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCreate_Mutation(\n    $campaignId: ObjectID!\n    $data: AdCreateInput!\n  ) {\n    addAd(campaignId: $campaignId, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AddCreate_Mutation(\n    $campaignId: ObjectID!\n    $data: AdCreateInput!\n  ) {\n    addAd(campaignId: $campaignId, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdCreate_QueryFragment on Query {\n    campaign(id: $campaignId) {\n      id\n      ...GetListItem_CampaignFragment\n    }\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    car(id: $carId, orgId: $orgId) {\n      id\n      ...GetListItem_CarFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AdCreate_QueryFragment on Query {\n    campaign(id: $campaignId) {\n      id\n      ...GetListItem_CampaignFragment\n    }\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    car(id: $carId, orgId: $orgId) {\n      id\n      ...GetListItem_CarFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdList_QueryFragment on Query {\n    ads {\n      ...GetListItem_AdFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AdList_QueryFragment on Query {\n    ads {\n      ...GetListItem_AdFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdList2_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        org {\n            id\n            name\n        }\n        ads {\n          ...GetListItem_AdFragment\n        }\n    }\n  }\n"): (typeof documents)["\n  fragment AdList2_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        org {\n            id\n            name\n        }\n        ads {\n          ...GetListItem_AdFragment\n        }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ListItemTrailing_AdFragment on Ad {\n    id\n  }\n"): (typeof documents)["\n  fragment ListItemTrailing_AdFragment on Ad {\n    id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_AdFragment on Ad {\n    id\n    createdAt\n    car {\n        id\n        brand\n        model\n        year\n        plate\n    }\n    campaign {\n        id\n        org {\n            id\n            name\n        }\n        periodDays\n        name\n    }\n    ...ListItemTrailing_AdFragment\n  }\n"): (typeof documents)["\n  fragment GetListItem_AdFragment on Ad {\n    id\n    createdAt\n    car {\n        id\n        brand\n        model\n        year\n        plate\n    }\n    campaign {\n        id\n        org {\n            id\n            name\n        }\n        periodDays\n        name\n    }\n    ...ListItemTrailing_AdFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AppSetActive_Mutatuion($id: ObjectID!, $active: Boolean!) {\n    appSetActive(id: $id, active: $active) {\n      id\n      active\n    }\n  }\n"): (typeof documents)["\n  mutation AppSetActive_Mutatuion($id: ObjectID!, $active: Boolean!) {\n    appSetActive(id: $id, active: $active) {\n      id\n      active\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment App_QueryFragment on Query {\n    app(id: $id) {\n      id\n      label\n      package\n      versionName\n      versionCode\n      active\n      ...Header_AppFragment\n    }\n  }\n"): (typeof documents)["\n  fragment App_QueryFragment on Query {\n    app(id: $id) {\n      id\n      label\n      package\n      versionName\n      versionCode\n      active\n      ...Header_AppFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AppCreate_Mutatuion($data: AppCreateInput!) {\n    appCreate(data: $data) {\n      id\n      active\n    }\n  }\n"): (typeof documents)["\n  mutation AppCreate_Mutatuion($data: AppCreateInput!) {\n    appCreate(data: $data) {\n      id\n      active\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Header_AppFragment on App {\n    id\n    ...Icon_AppFragment\n  }\n"): (typeof documents)["\n  fragment Header_AppFragment on App {\n    id\n    ...Icon_AppFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Icon_AppFragment on App {\n    id\n    icon\n  }\n"): (typeof documents)["\n  fragment Icon_AppFragment on App {\n    id\n    icon\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AppList_QueryFragment on Query {\n    apps {\n      ...GetListItem_AppFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AppList_QueryFragment on Query {\n    apps {\n      ...GetListItem_AppFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_AppFragment on App {\n    id\n    label\n    package\n    versionName\n    versionCode\n    active\n    ...Icon_AppFragment\n  }\n"): (typeof documents)["\n  fragment GetListItem_AppFragment on App {\n    id\n    label\n    package\n    versionName\n    versionCode\n    active\n    ...Icon_AppFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Campaign_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Campaign_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCampaign_Mutation($orgId: ObjectID!, $data: CampaignCreateInput!) {\n    addCampaign(orgId: $orgId, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n"): (typeof documents)["\n  mutation AddCampaign_Mutation($orgId: ObjectID!, $data: CampaignCreateInput!) {\n    addCampaign(orgId: $orgId, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CampaignCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CampaignCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveCampaign_Mutation($id: ObjectID!, $data: CampaignSaveInput!) {\n    saveCampaign(id: $id, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCampaign_Mutation($id: ObjectID!, $data: CampaignSaveInput!) {\n    saveCampaign(id: $id, data: $data) {\n      id\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CampaignEdit_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        name\n        ...GetListItem_OrgFragment\n      }\n    }\n    user {\n      orgsMember {\n        id\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CampaignEdit_QueryFragment on Query {\n    campaign(id: $id) {\n      id\n      name\n      carsCount\n      periodDays\n      regions\n      comment\n      org {\n        id\n        name\n        ...GetListItem_OrgFragment\n      }\n    }\n    user {\n      orgsMember {\n        id\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CampaignList_QueryFragment on Query {\n    campaigns(orgId: $orgId) {\n      ...GetListItem_CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CampaignList_QueryFragment on Query {\n    campaigns(orgId: $orgId) {\n      ...GetListItem_CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_CampaignFragment on Campaign {\n    id\n    name\n    periodDays\n    carsCount\n    org {\n        id\n        name\n    }\n  }\n"): (typeof documents)["\n  fragment GetListItem_CampaignFragment on Campaign {\n    id\n    name\n    periodDays\n    carsCount\n    org {\n        id\n        name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Car_QueryFragment on Query {\n    car(id: $id) {\n      id\n      year\n      plate\n      drivers {\n        id\n        ...GetListItem_OrgMemberFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      currentAd {\n        id\n        ...GetListItem_AdFragment\n      }\n      ads {\n        id\n        ...GetListItem_AdFragment\n      }\n      ...Header_CarFragment\n    }\n  }\n"): (typeof documents)["\n  fragment Car_QueryFragment on Query {\n    car(id: $id) {\n      id\n      year\n      plate\n      drivers {\n        id\n        ...GetListItem_OrgMemberFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      currentAd {\n        id\n        ...GetListItem_AdFragment\n      }\n      ads {\n        id\n        ...GetListItem_AdFragment\n      }\n      ...Header_CarFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCar_Mutation($orgId: ObjectID!, $data: CarCreateInput!) {\n    addCar(orgId: $orgId, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AddCar_Mutation($orgId: ObjectID!, $data: CarCreateInput!) {\n    addCar(orgId: $orgId, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CarCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    orgMembers(ids: $driverIds, orgId: $orgId) {\n      id\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CarCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n    orgMembers(ids: $driverIds, orgId: $orgId) {\n      id\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCarDriver_Mutation($carId: ObjectID!, $phone: String!) {\n    addCarDriver(carId: $carId, phone: $phone) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AddCarDriver_Mutation($carId: ObjectID!, $phone: String!) {\n    addCarDriver(carId: $carId, phone: $phone) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCar_Mutation($id: ObjectID!) {\n    deleteCar(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteCar_Mutation($id: ObjectID!) {\n    deleteCar(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Header_CarFragment on Car {\n    id\n    brand\n    model\n    plate\n  }\n"): (typeof documents)["\n  fragment Header_CarFragment on Car {\n    id\n    brand\n    model\n    plate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CarList_QueryFragment on Query {\n    cars(orgId: $orgId) {\n      id\n      currentAd {\n        id\n      }\n      ...GetListItem_CarFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CarList_QueryFragment on Query {\n    cars(orgId: $orgId) {\n      id\n      currentAd {\n        id\n      }\n      ...GetListItem_CarFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_CarFragment on Car {\n    id\n    brand\n    model\n    year\n    plate\n    org {\n        id\n        name\n    }\n    drivers {\n        id\n    }\n    currentAd {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n      \n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GetListItem_CarFragment on Car {\n    id\n    brand\n    model\n    year\n    plate\n    org {\n        id\n        name\n    }\n    drivers {\n        id\n    }\n    currentAd {\n      id\n      createdAt\n      campaign {\n        id\n        periodDays\n      \n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveCarDriver_Mutation($carId: ObjectID!, $memberId: ObjectID!) {\n    removeCarDriver(carId: $carId, memberId: $memberId) {\n      id\n      drivers {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveCarDriver_Mutation($carId: ObjectID!, $memberId: ObjectID!) {\n    removeCarDriver(carId: $carId, memberId: $memberId) {\n      id\n      drivers {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ControlSettings_Mutation($data: SettingsSaveInput!) {\n    saveSettings(data: $data) {\n      ...ControlSettings_Fragment\n    }\n  }\n"): (typeof documents)["\n  mutation ControlSettings_Mutation($data: SettingsSaveInput!) {\n    saveSettings(data: $data) {\n      ...ControlSettings_Fragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ControlSettings_Fragment on AppSettings {\n    id\n    whitelistEnabled\n    whitelist\n  }\n"): (typeof documents)["\n  fragment ControlSettings_Fragment on AppSettings {\n    id\n    whitelistEnabled\n    whitelist\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ControlSettings_QueryFragment on Query {\n    settings {\n      ...ControlSettings_Fragment\n    }\n  }\n"): (typeof documents)["\n  fragment ControlSettings_QueryFragment on Query {\n    settings {\n      ...ControlSettings_Fragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Device_QueryFragment on Query {\n    device(id: $id) {\n      ...DeviceMap_QueryFragment\n      id\n      appId\n      appLocale\n      appVersion\n      createdAt\n      updatedAt\n      languageTags\n      lastIp\n      platform\n      timezone\n      online\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Device_QueryFragment on Query {\n    device(id: $id) {\n      ...DeviceMap_QueryFragment\n      id\n      appId\n      appLocale\n      appVersion\n      createdAt\n      updatedAt\n      languageTags\n      lastIp\n      platform\n      timezone\n      online\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DeviceList_QueryFragment on Query {\n    devices {\n      ...GetListItem_DeviceFragment\n    }\n  }\n"): (typeof documents)["\n  fragment DeviceList_QueryFragment on Query {\n    devices {\n      ...GetListItem_DeviceFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DeviceMap_QueryFragment on Device {\n    id\n    location {\n      coords {\n        latitude\n        longitude\n        altitude\n        accuracy\n        altitudeAccuracy\n        heading\n        speed\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DeviceMap_QueryFragment on Device {\n    id\n    location {\n      coords {\n        latitude\n        longitude\n        altitude\n        accuracy\n        altitudeAccuracy\n        heading\n        speed\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_DeviceFragment on Device {\n    id\n    appId\n    platform\n    appVersion\n    online\n  }\n"): (typeof documents)["\n  fragment GetListItem_DeviceFragment on Device {\n    id\n    appId\n    platform\n    appVersion\n    online\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendContact_Mutation($name: String!, $email: String!, $phone: String!) {\n    sendContact(name: $name, email: $email, phone: $phone)\n  }\n"): (typeof documents)["\n  mutation SendContact_Mutation($name: String!, $email: String!, $phone: String!) {\n    sendContact(name: $name, email: $email, phone: $phone)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MediaAdSetEnabled_Mutatuion($id: ObjectID!, $enabled: Boolean!) {\n    mediaAdSetEnabled(id: $id, enabled: $enabled) {\n      id\n      enabled\n    }\n  }\n"): (typeof documents)["\n  mutation MediaAdSetEnabled_Mutatuion($id: ObjectID!, $enabled: Boolean!) {\n    mediaAdSetEnabled(id: $id, enabled: $enabled) {\n      id\n      enabled\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MediaAd_QueryFragment on Query {\n    mediaAd(id: $id) {\n      id\n      name\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      upload {\n        ...UploadFragment\n      }\n      enabled\n      impressionsCount\n      viewsCount\n    }\n  }\n"): (typeof documents)["\n  fragment MediaAd_QueryFragment on Query {\n    mediaAd(id: $id) {\n      id\n      name\n      org {\n        id\n        ...GetListItem_OrgFragment\n      }\n      upload {\n        ...UploadFragment\n      }\n      enabled\n      impressionsCount\n      viewsCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation MediaAdCreate_Mutation(\n    $data: MediaAdCreateInput!\n  ) {\n    mediaAdCreate(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation MediaAdCreate_Mutation(\n    $data: MediaAdCreateInput!\n  ) {\n    mediaAdCreate(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MediaAdCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n"): (typeof documents)["\n  fragment MediaAdCreate_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MediaAdList_QueryFragment on Query {\n    mediaAds {\n      ...GetListItem_MediaAdFragment\n    }\n  }\n"): (typeof documents)["\n  fragment MediaAdList_QueryFragment on Query {\n    mediaAds {\n      ...GetListItem_MediaAdFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_MediaAdFragment on MediaAd {\n    id\n    name\n    org {\n      id\n      name\n    }\n    enabled\n    impressionsCount\n    viewsCount\n  }\n"): (typeof documents)["\n  fragment GetListItem_MediaAdFragment on MediaAd {\n    id\n    name\n    org {\n      id\n      name\n    }\n    enabled\n    impressionsCount\n    viewsCount\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Org_QueryFragment on Query {\n    org(id: $id) {\n      id\n      name\n      tin\n      entityTypeName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n      member {\n        id\n        role\n        inviteStatus\n      }\n      ...Header_OrgFragment\n    }\n  }\n"): (typeof documents)["\n  fragment Org_QueryFragment on Query {\n    org(id: $id) {\n      id\n      name\n      tin\n      entityTypeName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n      member {\n        id\n        role\n        inviteStatus\n      }\n      ...Header_OrgFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query OrgInfo_Query($tin: Int!) {\n    orgInfo(tin: $tin) {\n      entityTypeName\n      displayName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n    }\n  }\n"): (typeof documents)["\n  query OrgInfo_Query($tin: Int!) {\n    orgInfo(tin: $tin) {\n      entityTypeName\n      displayName\n      activityTypeName\n      registrationAuthorityName\n      registrationDate\n      statusName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddOrg_Mutation($tin: Int!, $jobTitle: String!) {\n    addOrg(tin: $tin, jobTitle: $jobTitle) {\n      id\n      org {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddOrg_Mutation($tin: Int!, $jobTitle: String!) {\n    addOrg(tin: $tin, jobTitle: $jobTitle) {\n      id\n      org {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Header_OrgFragment on Org {\n    id\n    name\n    members {\n      id\n    }\n    member {\n      id\n      role\n    }\n  }\n"): (typeof documents)["\n  fragment Header_OrgFragment on Org {\n    id\n    name\n    members {\n      id\n    }\n    member {\n      id\n      role\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgList_QueryFragment on Query {\n    orgs(member: $member) {\n      ...GetListItem_OrgFragment\n    }\n  }\n"): (typeof documents)["\n  fragment OrgList_QueryFragment on Query {\n    orgs(member: $member) {\n      ...GetListItem_OrgFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ListItemFooter_OrgFragment on Org {\n    id\n    name\n    member {\n        id\n        inviteStatus\n        inviter {\n            id\n            firstName\n            lastName\n        }\n    }\n  }\n"): (typeof documents)["\n  fragment ListItemFooter_OrgFragment on Org {\n    id\n    name\n    member {\n        id\n        inviteStatus\n        inviter {\n            id\n            firstName\n            lastName\n        }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteOrg_Mutation($id: ObjectID!) {\n    deleteOrg(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteOrg_Mutation($id: ObjectID!) {\n    deleteOrg(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgSwitcher_QueryFragment on Query {\n    user {\n      id\n      orgsMember {\n        id\n        jobTitle\n        role\n        inviteStatus\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OrgSwitcher_QueryFragment on Query {\n    user {\n      id\n      orgsMember {\n        id\n        jobTitle\n        role\n        inviteStatus\n        org {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_OrgFragment on Org {\n    id\n    name\n    tin\n    member @skip(if: $hideCurrentOrgMemberRole) {\n        id\n        jobTitle\n        role\n    }\n    ...ListItemFooter_OrgFragment\n  }\n"): (typeof documents)["\n  fragment GetListItem_OrgFragment on Org {\n    id\n    name\n    tin\n    member @skip(if: $hideCurrentOrgMemberRole) {\n        id\n        jobTitle\n        role\n    }\n    ...ListItemFooter_OrgFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RejectOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    rejectOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n"): (typeof documents)["\n  mutation RejectOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    rejectOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AcceptOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    acceptOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptOrgMemberInvite_Mutation($orgId: ObjectID!) {\n    acceptOrgMemberInvite(orgId: $orgId) {\n      id\n      inviteStatus\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgMember_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        phone\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OrgMember_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        phone\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResendOrgMemberInvite_Mutation($id: ObjectID!) {\n    resendOrgMemberInvite(id: $id) {\n      id\n      inviteStatus\n    }\n  }\n"): (typeof documents)["\n  mutation ResendOrgMemberInvite_Mutation($id: ObjectID!) {\n    resendOrgMemberInvite(id: $id) {\n      id\n      inviteStatus\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation TransferOrg_Mutation($orgId: ObjectID!, $memberId: ObjectID!) {\n    trasferOrg(orgId: $orgId, memberId: $memberId) {\n      id\n      role\n      permissions\n    }\n  }\n"): (typeof documents)["\n  mutation TransferOrg_Mutation($orgId: ObjectID!, $memberId: ObjectID!) {\n    trasferOrg(orgId: $orgId, memberId: $memberId) {\n      id\n      role\n      permissions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveOrgMember_Mutation(\n    $id: ObjectID!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    saveOrgMember(id: $id, jobTitle: $jobTitle, permissions: $permissions) {\n      id\n      jobTitle\n      permissions\n    }\n  }\n"): (typeof documents)["\n  mutation SaveOrgMember_Mutation(\n    $id: ObjectID!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    saveOrgMember(id: $id, jobTitle: $jobTitle, permissions: $permissions) {\n      id\n      jobTitle\n      permissions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgMemberEdit_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        firstName\n        lastName\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        name\n        member {\n          id\n          permissions\n          role\n        }\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n    user {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment OrgMemberEdit_QueryFragment on Query {\n    orgMember(id: $id) {\n      id\n      orgId\n      inviteStatus\n      role\n      jobTitle\n      permissions\n      cars {\n        id\n        brand\n        model\n        year\n        plate\n        drivers {\n          id\n        }\n      }\n      user {\n        id\n        firstName\n        lastName\n        ...Photo_UserFragment\n        ...GetListItem_UserFragment\n      }\n      org {\n        id\n        name\n        member {\n          id\n          permissions\n          role\n        }\n        ...GetListItem_OrgFragment\n        ...GetAdminFeaturesSection_OrgMemberFragment\n      }\n    }\n    user {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InviteOrgMember_Mutation(\n    $orgId: ObjectID!\n    $phone: String!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    inviteOrgMember(\n      orgId: $orgId\n      phone: $phone\n      jobTitle: $jobTitle\n      permissions: $permissions\n    ) {\n      id\n      jobTitle\n      user {\n        id\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InviteOrgMember_Mutation(\n    $orgId: ObjectID!\n    $phone: String!\n    $jobTitle: String!\n    $permissions: [OrgMemberPermission!]!\n  ) {\n    inviteOrgMember(\n      orgId: $orgId\n      phone: $phone\n      jobTitle: $jobTitle\n      permissions: $permissions\n    ) {\n      id\n      jobTitle\n      user {\n        id\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgMemberInvite_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n      ...GetAdminFeaturesSection_OrgMemberFragment\n    }\n  }\n"): (typeof documents)["\n  fragment OrgMemberInvite_QueryFragment on Query {\n    org(id: $orgId) {\n      id\n      ...GetListItem_OrgFragment\n      ...GetAdminFeaturesSection_OrgMemberFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrgMemberList_QueryFragment on Query {\n    orgMembers(orgId: $orgId) {\n      id\n      role\n      orgId\n      inviteStatus\n      permissions\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n"): (typeof documents)["\n  fragment OrgMemberList_QueryFragment on Query {\n    orgMembers(orgId: $orgId) {\n      id\n      role\n      orgId\n      inviteStatus\n      permissions\n      ...GetListItem_OrgMemberFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetAdminFeaturesSection_OrgMemberFragment on Org {\n    member {\n        id\n        permissions\n    }\n  }\n"): (typeof documents)["\n  fragment GetAdminFeaturesSection_OrgMemberFragment on Org {\n    member {\n        id\n        permissions\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_OrgMemberFragment on OrgMember {\n    id\n    inviteStatus\n    role\n    jobTitle\n    user {\n        firstName\n        lastName\n        phone\n        ...Photo_UserFragment\n    }\n    inviter {\n        id\n        firstName\n    }\n  }\n"): (typeof documents)["\n  fragment GetListItem_OrgMemberFragment on OrgMember {\n    id\n    inviteStatus\n    role\n    jobTitle\n    user {\n        firstName\n        lastName\n        phone\n        ...Photo_UserFragment\n    }\n    inviter {\n        id\n        firstName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveOrgMember_Mutation($id: ObjectID!) {\n    removeOrgMember(id: $id)\n  }\n"): (typeof documents)["\n  mutation RemoveOrgMember_Mutation($id: ObjectID!) {\n    removeOrgMember(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AssetFragment on Upload {\n   id\n  }\n"): (typeof documents)["\n  fragment AssetFragment on Upload {\n   id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ProfileEdit_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"): (typeof documents)["\n  mutation ProfileEdit_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileEdit_QueryFragment on Query {\n    user {\n      id\n      phone\n      firstName\n      middleName\n      lastName\n      ...ProfileEditHeader_UserFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ProfileEdit_QueryFragment on Query {\n    user {\n      id\n      phone\n      firstName\n      middleName\n      lastName\n      ...ProfileEditHeader_UserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileEditHeader_UserFragment on User {\n    ...Photo_UserFragment\n  }\n"): (typeof documents)["\n  fragment ProfileEditHeader_UserFragment on User {\n    ...Photo_UserFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Report_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        periodDays\n        carsCount\n        regions\n        status\n        plannedAt\n        startedAt\n        org {\n            id\n            name\n        }\n        ads {\n            id\n            createdAt\n            car {\n                id\n                plate\n                brand\n                model\n                coordinates\n            }\n        }\n        ...ReportMap_QueryFragment\n    }\n  }\n"): (typeof documents)["\n  fragment Report_QueryFragment on Query {\n    campaign(id: $id) {\n        id\n        name\n        periodDays\n        carsCount\n        regions\n        status\n        plannedAt\n        startedAt\n        org {\n            id\n            name\n        }\n        ads {\n            id\n            createdAt\n            car {\n                id\n                plate\n                brand\n                model\n                coordinates\n            }\n        }\n        ...ReportMap_QueryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReportMap_QueryFragment on Campaign {\n    ads {\n        id\n        car {\n            id\n            coordinates\n        }\n    }\n  }\n"): (typeof documents)["\n  fragment ReportMap_QueryFragment on Campaign {\n    ads {\n        id\n        car {\n            id\n            coordinates\n        }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      ...Header_SettingsFragment\n      orgsMember {\n        id\n        inviteStatus\n        org {\n          id\n          ...GetListItem_OrgFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      ...Header_SettingsFragment\n      orgsMember {\n        id\n        inviteStatus\n        org {\n          id\n          ...GetListItem_OrgFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Header_SettingsFragment on User {\n    id\n    firstName\n    lastName\n    ...Photo_UserFragment\n  }\n"): (typeof documents)["\n  fragment Header_SettingsFragment on User {\n    id\n    firstName\n    lastName\n    ...Photo_UserFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendCode_Mutation($deviceId: String!, $phone: String!) {\n    sendCode(deviceId: $deviceId, phone: $phone) {\n      type\n      codeLength\n      code\n    }\n  }\n"): (typeof documents)["\n  mutation SendCode_Mutation($deviceId: String!, $phone: String!) {\n    sendCode(deviceId: $deviceId, phone: $phone) {\n      type\n      codeLength\n      code\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignIn_Mutation($deviceId: String!, $phone: String!, $code: String!) {\n    signIn(deviceId: $deviceId, phone: $phone, code: $code) {\n      token\n      user {\n        id\n        signUpRequired\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SignIn_Mutation($deviceId: String!, $phone: String!, $code: String!) {\n    signIn(deviceId: $deviceId, phone: $phone, code: $code) {\n      token\n      user {\n        id\n        signUpRequired\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SignUp_QueryFragment on Query {\n    user {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"): (typeof documents)["\n  fragment SignUp_QueryFragment on Query {\n    user {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignUp_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"): (typeof documents)["\n  mutation SignUp_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n      firstName\n      lastName\n      middleName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UploadFragment on Upload {\n    id\n    fileName\n    mime\n    signedUrl\n    cloudType\n    thumbhash\n    url\n    previewUrl: url(size: 200)\n    localUri @client\n    progress @client {\n      progress\n    }\n    app @client {\n      label\n      package\n      versionName\n      versionCode\n      icon\n    }\n    ...UploadProgressFragment\n  }\n"): (typeof documents)["\n  fragment UploadFragment on Upload {\n    id\n    fileName\n    mime\n    signedUrl\n    cloudType\n    thumbhash\n    url\n    previewUrl: url(size: 200)\n    localUri @client\n    progress @client {\n      progress\n    }\n    app @client {\n      label\n      package\n      versionName\n      versionCode\n      icon\n    }\n    ...UploadProgressFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UploadCreate_Mutation($data: [UploadCreateInput!]!) {\n    uploadsCreate(data: $data) {\n      ...UploadFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UploadCreate_Mutation($data: [UploadCreateInput!]!) {\n    uploadsCreate(data: $data) {\n      ...UploadFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UploadUpdate_Mutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      ...UploadFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UploadUpdate_Mutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      ...UploadFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UploadProgressFragment on Upload {\n    id\n    progress @client {\n      progress\n    }\n  }\n"): (typeof documents)["\n  fragment UploadProgressFragment on Upload {\n    id\n    progress @client {\n      progress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment User_QueryFragment on Query {\n    user(id: $id) {\n      id\n      phone\n      inviter {\n        id\n        firstName\n        lastName\n      }\n      orgsMember {\n        id\n        org {\n          ...GetListItem_OrgFragment\n        }\n        cars {\n          ...GetListItem_CarFragment\n        }\n      }\n      ...Header_UserFragment\n    }\n  }\n"): (typeof documents)["\n  fragment User_QueryFragment on Query {\n    user(id: $id) {\n      id\n      phone\n      inviter {\n        id\n        firstName\n        lastName\n      }\n      orgsMember {\n        id\n        org {\n          ...GetListItem_OrgFragment\n        }\n        cars {\n          ...GetListItem_CarFragment\n        }\n      }\n      ...Header_UserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Header_UserFragment on User {\n    firstName\n    middleName\n    lastName\n    phone\n    signUpRequired\n    ...Photo_UserFragment\n  }\n"): (typeof documents)["\n  fragment Header_UserFragment on User {\n    firstName\n    middleName\n    lastName\n    phone\n    signUpRequired\n    ...Photo_UserFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserInvite_Mutation(\n    $phone: String!\n  ) {\n    inviteUser(phone: $phone) {\n      id,\n      phone\n    }\n  }\n"): (typeof documents)["\n  mutation UserInvite_Mutation(\n    $phone: String!\n  ) {\n    inviteUser(phone: $phone) {\n      id,\n      phone\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserList_QueryFragment on Query {\n    users {\n      ...GetListItem_UserFragment\n    }\n  }\n"): (typeof documents)["\n  fragment UserList_QueryFragment on Query {\n    users {\n      ...GetListItem_UserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Photo_UserFragment on User {\n    id\n  }\n"): (typeof documents)["\n  fragment Photo_UserFragment on User {\n    id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GetListItem_UserFragment on User {\n    id\n    phone\n    firstName\n    lastName\n    signUpRequired\n    inviter {\n      id\n      firstName\n      lastName\n    }\n    ...Photo_UserFragment\n  }\n"): (typeof documents)["\n  fragment GetListItem_UserFragment on User {\n    id\n    phone\n    firstName\n    lastName\n    signUpRequired\n    inviter {\n      id\n      firstName\n      lastName\n    }\n    ...Photo_UserFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdDraft_Query {\n    adDraft @client {\n      orgId\n      campaignId\n      carId\n      upload {\n        ...UploadFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdDraft_Query {\n    adDraft @client {\n      orgId\n      campaignId\n      carId\n      upload {\n        ...UploadFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AppDraft_Query {\n    appDraft @client {\n      upload {\n        id\n        app {\n          label\n          package\n          versionName\n          versionCode\n          icon\n        }\n        ...UploadFragment\n      }\n      active @client\n    }\n  }\n"): (typeof documents)["\n  query AppDraft_Query {\n    appDraft @client {\n      upload {\n        id\n        app {\n          label\n          package\n          versionName\n          versionCode\n          icon\n        }\n        ...UploadFragment\n      }\n      active @client\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CampaignDraft_Query {\n    campaignDraft @client {\n      orgId\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n      uploads {\n        ...UploadFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CampaignDraft_Query {\n    campaignDraft @client {\n      orgId\n      name\n      periodDays\n      carsCount\n      regions\n      comment\n      uploads {\n        ...UploadFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CarDraft_Query {\n    carDraft @client {\n      orgId\n      plate\n      brand\n      model\n      year\n      driverIds\n    }\n  }\n"): (typeof documents)["\n  query CarDraft_Query {\n    carDraft @client {\n      orgId\n      plate\n      brand\n      model\n      year\n      driverIds\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MediaAdDraft_Query {\n    mediaAdDraft @client {\n      orgId\n      name\n      enabled\n      upload {\n        id\n        ...UploadFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query MediaAdDraft_Query {\n    mediaAdDraft @client {\n      orgId\n      name\n      enabled\n      upload {\n        id\n        ...UploadFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription Device_Subscription($id: String!) {\n    device(id: $id) {\n      id\n      updatedAt\n      online\n      wsIds\n      appId\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription Device_Subscription($id: String!) {\n    device(id: $id) {\n      id\n      updatedAt\n      online\n      wsIds\n      appId\n      location {\n        coords {\n          latitude\n          longitude\n          altitude\n          accuracy\n          altitudeAccuracy\n          heading\n          speed\n        }\n        timestamp\n        mocked\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription Online_Subscription {\n    online\n  }\n"): (typeof documents)["\n  subscription Online_Subscription {\n    online\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignOut_Mutation($token: String!) {\n    signOut(token: $token)\n  }\n"): (typeof documents)["\n  mutation SignOut_Mutation($token: String!) {\n    signOut(token: $token)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UseUpdateCurrentUserPhoto_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UseUpdateCurrentUserPhoto_Mutation($data: UserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;