import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { DeviceList } from "components/Device/DeviceList";

const ControlDevices_Query = gql(`
  query ControlDevices_Query {
    ...DeviceList_QueryFragment
  }
`);

export default function Page() {
  const { data } = useQuery(ControlDevices_Query);

  return <DeviceList query={data} />;
}
