import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { MediaAdCreate } from "components/MediaAd/MediaAdCreate";
import { useDraft } from "components/drafts/drafts";
import { useLocalSearchParams } from "expo-router";
import { useEffect } from "react";

export const MediaAdCreate_Query = gql(`
   query MediaAdCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {
    ...MediaAdCreate_QueryFragment
  }
`);

export default function Page() {
  const [draft, setDraft] = useDraft("mediaAdDraft");
  const { orgId } = useLocalSearchParams<{ orgId?: string }>();

  useEffect(() => {
    orgId && setDraft({ orgId });
  }, [orgId, setDraft]);

  const { data } = useQuery(MediaAdCreate_Query, {
    variables: {
      orgId: draft.orgId,
    },
  });

  return <MediaAdCreate query={data} />;
}
