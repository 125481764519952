import {
  ActivityIndicator,
  Platform,
  Shimmer,
  StyleSheet,
  View,
  toast,
  useColors,
} from "components/common";
import { UseAssetPickerProps, useAssetPicker } from "hooks/useAssetPicker";
import { RectButton } from "react-native-gesture-handler";
import Animated, {
  FadeIn,
  LinearTransition,
  ZoomOut,
} from "react-native-reanimated";
import PlusIcon from "assets/icons/plus.svg";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { FetchResult, useMutation } from "@apollo/client";
import { UploadFragment } from "./Upload";
import { DocumentPickerAsset } from "expo-document-picker";
import { ImagePickerAsset } from "expo-image-picker";
import axios from "axios";
import { UploadApiResponse } from "cloudinary-react-native/lib/typescript/src/api/upload/model/params/upload-params";
import { Buffer } from "buffer";
import { cache } from "utils/appolo";
import {
  UploadCloudinaryResourceType,
  UploadFragmentFragment,
  UploadUpdate_MutationMutation,
} from "__generated__/graphql";
import { getApkInfo } from "utils/apk";
import { uploadAbortRef } from "./uploadAbortRef";

const UploadCreate_Mutation = gql(`
  mutation UploadCreate_Mutation($data: [UploadCreateInput!]!) {
    uploadsCreate(data: $data) {
      ...UploadFragment
    }
  }
`);

const UploadUpdate_Mutation = gql(`
  mutation UploadUpdate_Mutation($id: ObjectID! $data: UploadUpdateInput!) {
    uploadUpdate(id: $id data: $data) {
      ...UploadFragment
    }
  }
`);

type UploadCreateProps = {
  options?: UseAssetPickerProps;
  selectionLimit?: number;
  loading?: boolean;
  onUploadsCreate?: (upload: FragmentType<typeof UploadFragment>[]) => void;
};
export const UploadCreate = (props: UploadCreateProps) => {
  const colors = useColors();
  const [pick] = useAssetPicker({
    multiple: true,
    selectionLimit: props.selectionLimit,
    ...props.options,
  });

  const [createUploads, { loading }] = useMutation(UploadCreate_Mutation);
  const [updateUpload] = useMutation(UploadUpdate_Mutation);

  return (
    <Animated.View
      style={styles.contaienr}
      entering={FadeIn}
      exiting={ZoomOut}
      layout={LinearTransition}
    >
      <View style={styles.wrap} colorName="card">
        {loading ? (
          <ActivityIndicator />
        ) : (
          <PlusIcon
            style={styles.icon}
            color={colors.textSecondary}
            width={50}
            height={50}
          />
        )}
        {props.loading && <Shimmer style={styles.shimmer} />}
        {!loading && (
          <RectButton
            style={styles.button}
            enabled={!props.loading && !loading}
            onPress={() => {
              pick()
                .then(async (assets) => {
                  const { data } = await createUploads({
                    variables: {
                      data: assets.map((asset) => {
                        const isDoc = "name" in asset;
                        const fileName = isDoc ? asset.name : asset.fileName;
                        const size = isDoc ? asset.size : asset.fileSize;
                        const width = isDoc ? undefined : asset.width;
                        const height = isDoc ? undefined : asset.height;
                        const duration = isDoc ? undefined : asset.duration;

                        return {
                          mime: asset.mimeType,
                          fileName,
                          size,
                          width,
                          height,
                          duration,
                        };
                      }),
                    },
                  });

                  return Promise.all(
                    data?.uploadsCreate.map(async (upload, index) => {
                      const asset = assets[index];

                      return [
                        {
                          ...upload,
                          localUri: asset.uri,
                          app:
                            asset.mimeType ===
                            "application/vnd.android.package-archive"
                              ? await getApkInfo(asset)
                              : null,
                          progress: {
                            progress: 0,
                          },
                        },
                        assets[index],
                      ] as const;
                    }) || []
                  );
                })
                .then((uploads) => {
                  if (!uploads.length) return;

                  uploads.forEach(([uploadFragment, asset]) => {
                    const upload = getFragmentData(
                      UploadFragment,
                      uploadFragment
                    );

                    new Promise<FetchResult<UploadUpdate_MutationMutation>>(
                      (resolve) => {
                        if (!upload.signedUrl) return;

                        const controller = new AbortController();

                        uploadAbortRef.current[upload.id] = () => {
                          controller.abort();
                          cache.evict({ id: cache.identify(upload) });
                        };

                        if (upload.cloudType === "aws") {
                          axios
                            .put(
                              upload.signedUrl,
                              ("file" in asset && asset.file) as File,
                              {
                                headers: {
                                  "Content-Type": upload.mime,
                                  "x-amz-acl": "public-read",
                                },
                                signal: controller.signal,
                                onUploadProgress: (progress) => {
                                  cache.modify<UploadFragmentFragment>({
                                    id: cache.identify(upload),
                                    fields: {
                                      progress: () => ({
                                        progress: progress.progress || 0,
                                      }),
                                    },
                                  });
                                },
                              }
                            )
                            .then(() => {
                              delete uploadAbortRef.current[upload.id];
                              return updateUpload({
                                variables: {
                                  id: upload.id,
                                  data: { completed: true },
                                },
                              });
                            })
                            .then(resolve);
                        } else {
                          const formData = new FormData();

                          if (Platform.OS === "web")
                            formData.append(
                              "file",
                              ("file" in asset && asset.file) as File
                            );
                          else
                            formData.append("file", {
                              uri: asset.uri,
                              name: upload.fileName || "file",
                              type: asset.mimeType,
                            } as unknown as File);

                          axios
                            .post<UploadApiResponse>(
                              upload.signedUrl,
                              formData,
                              {
                                signal: controller.signal,
                                onUploadProgress: (progress) => {
                                  cache.modify<UploadFragmentFragment>({
                                    id: cache.identify(upload),
                                    fields: {
                                      progress: () => ({
                                        progress: progress.progress || 0,
                                      }),
                                    },
                                  });
                                },
                              }
                            )
                            .then(({ data }) => {
                              delete uploadAbortRef.current[upload.id];
                              return updateUpload({
                                variables: {
                                  id: upload.id,
                                  data: {
                                    completed: true,
                                    cloudinary: {
                                      public_id: data.public_id,
                                      resource_type:
                                        data.resource_type as UploadCloudinaryResourceType,
                                      url: data.url,
                                      secure_url: data.secure_url,
                                    },
                                  },
                                },
                              });
                            })
                            .then(resolve);
                        }
                      }
                    ).then(({ data }) => {
                      if (data?.uploadUpdate)
                        cache.modify<UploadFragmentFragment>({
                          id: cache.identify(upload),
                          fields: {
                            localUri: () => null,
                            progress: () => null,
                          },
                        });
                      else cache.evict({ id: cache.identify(upload) });
                    });
                  }, []);

                  props.onUploadsCreate?.(uploads.map(([upload]) => upload));
                })
                .catch((error: Error) => {
                  error.message !== "canceled" && toast(error.message);
                });
            }}
          />
        )}
      </View>
    </Animated.View>
  );
};

export async function assetToFile(
  asset: ImagePickerAsset | DocumentPickerAsset
): Promise<File> {
  const base64 = asset.uri.split(",")[1];
  const buffer = Buffer.from(base64, "base64");
  const blob = new Blob([buffer], { type: asset.mimeType });
  const fileName = isRaw(asset.mimeType)
    ? "file"
    : "name" in asset
    ? asset.name
    : asset.fileName;

  return new File([blob], fileName || "", {
    type: isRaw(asset.mimeType) ? "application/octet-stream" : asset.mimeType,
  });
}

const isRaw = (mime?: string) =>
  !mime?.startsWith("image/") && !mime?.startsWith("video/");

const styles = StyleSheet.create({
  contaienr: {
    minWidth: 90,
    width: `${100 / 4}%`,
    aspectRatio: 1,
    padding: 4,
  },
  wrap: {
    flex: 1,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  icon: {
    opacity: 0.7,
  },
  shimmer: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    ...StyleSheet.absoluteFillObject,
  },
});
