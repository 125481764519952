import "react-native-get-random-values";
import "utils/metrica";
import "./_layout.css";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import Head from "expo-router/head";
import { inject } from "@vercel/analytics";
import { injectSpeedInsights } from "@vercel/speed-insights";
import { usePendingSignOut } from "hooks/usePendingSignOut";
import { useYandexMertika } from "hooks/useYandexMertika";
import { ApolloProvider } from "@apollo/client";
import { appoloClient } from "utils/appolo";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useOnline } from "hooks/useOnline";
import { ThemeProvider } from "@react-navigation/native";
import {
  Platform,
  ToastProvider,
  UploadsManagerProvider,
  View,
  useColorScheme,
  NetworkError,
} from "components/common";
import { Slot } from "expo-router";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "store";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { selectLang } from "store/settings";
import { useEffect } from "react";
import { changeLanguage } from "i18next";
import { DarkTheme, DefaultTheme } from "constants/Theme";
import { useIsRTL } from "hooks/useIsRTL";
import { StatusBar } from "expo-status-bar";
export { ErrorBoundary } from "expo-router";

if (Platform.OS === "web") {
  inject();
  injectSpeedInsights();
}

export const unstable_settings = {
  initialRouteName: "index",
};

export default function RootLayout() {
  return (
    <ApolloProvider client={appoloClient}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <Head>
          <meta property="expo:handoff" content="true" />
        </Head>
        <ActionSheetProvider>
          <Provider store={store}>
            {typeof window === "undefined" ? (
              <>
                <ThemeProvider value={DefaultTheme}>
                  <RootLayoutNav />
                </ThemeProvider>
              </>
            ) : (
              <PersistGate persistor={persistor}>
                <ThemeProvider value={DefaultTheme}>
                  <RootLayoutNav />
                </ThemeProvider>
              </PersistGate>
            )}
          </Provider>
        </ActionSheetProvider>
      </GestureHandlerRootView>
    </ApolloProvider>
  );
}

function RootLayoutNav() {
  const colorScheme = useColorScheme();
  const lang = useSelector(selectLang);
  const isRTL = useIsRTL();

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  useYandexMertika();
  usePendingSignOut();
  useOnline();

  return (
    <ThemeProvider value={colorScheme === "dark" ? DarkTheme : DefaultTheme}>
      <NetworkError />
      <BottomSheetModalProvider>
        <View style={{ flex: 1 }} dir={isRTL ? "rtl" : "ltr"}>
          <StatusBar
            style={colorScheme === "dark" ? "light" : "dark"}
            animated
          />
          <ToastProvider>
            <UploadsManagerProvider>
              <Slot />
            </UploadsManagerProvider>
          </ToastProvider>
        </View>
      </BottomSheetModalProvider>
    </ThemeProvider>
  );
}
