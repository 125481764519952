import { FragmentType, getFragmentData, gql } from "__generated__";
import { Shimmer } from "components/common";
import { Image, ImageStyle } from "expo-image";
import { StyleProp } from "react-native";

const Icon_AppFragment = gql(`
  fragment Icon_AppFragment on App {
    id
    icon
  }
`);

type AppIconProps = {
  app?: FragmentType<typeof Icon_AppFragment> | null;
  size?: number;
};

export const AppIcon = (props: AppIconProps) => {
  const app = getFragmentData(Icon_AppFragment, props.app);

  const size = props.size || 100;
  const style: StyleProp<ImageStyle> = {
    width: size,
    height: size,
    borderRadius: size / 10,
  };

  if (app)
    return <Image style={style} source={{ uri: app?.icon || undefined }} />;

  return <Shimmer style={style} />;
};
