import { SectionListMenu } from "components/common";
import { Link } from "expo-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { Upload } from "components/Uploads/Upload";

export const Campaign_QueryFragment = gql(`
  fragment Campaign_QueryFragment on Query {
    campaign(id: $id) {
      id
      name
      carsCount
      periodDays
      regions
      comment
      org {
        id
        ...GetListItem_OrgFragment
      }
    }
  }
`);

export type CampaignProps = {
  query?: FragmentType<typeof Campaign_QueryFragment>;
};

export const Campaign = (props: CampaignProps) => {
  const query = getFragmentData(Campaign_QueryFragment, props.query);
  const { t } = useTranslation("Campaign");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "org",
        data: [getOrgListItem({ org: query?.campaign?.org })],
      },
      {
        key: "items",
        data: [
          {
            key: "ads",
            title: t("ads"),
            Link: query ? (
              <Link href={`/campaign/${query.campaign?.id}/ads/`} />
            ) : undefined,
          },
        ],
      },
      {
        key: "info",
        data: [
          {
            key: "name",
            label: t("name"),
            loading: !query,
            title: query?.campaign?.name,
          },
          {
            key: "carsCount",
            label: t("carsCount"),
            loading: !query,
            title: query?.campaign?.carsCount,
          },
          {
            key: "periodDays",
            label: t("periodDays"),
            loading: !query,
            title: query?.campaign?.periodDays,
          },
          {
            key: "regions",
            label: t("regions"),
            loading: !query,
            title: query?.campaign?.regions?.join(", "),
          },
          {
            key: "comment",
            visible: !!query?.campaign?.comment,
            label: t("comment"),
            title: query?.campaign?.comment || undefined,
          },
        ],
      },
      {
        key: "layouts",
        title: t("layouts"),
        // visible: !!query?.campaign?.attachments?.length,
        data: [
          {
            key: "layouts",
            Component: <Upload />,
          },
        ],
      },
    ];
  }, [query, t]);

  return <SectionListMenu sections={sections} />;
};
