import { Platform } from "react-native";
import { omit } from "./omit";
import Constants from "expo-constants";
import { store } from "store";

export const getDeviceInfo = () => {
  const state = store.getState();
  const device = {
    ...omit(state.device, "_persist", "online", "pushToken"),
    appId: Platform.select({
      android: Constants.expoConfig?.android?.package,
      ios: Constants.expoConfig?.ios?.bundleIdentifier,
      default:
        typeof Constants.expoConfig?.extra?.appId === "string"
          ? Constants.expoConfig?.extra?.appId
          : "unknown",
    }),
    appVersion: Constants.expoConfig?.version || "unknown",
    appLocale: state.settings.lang,
  };
  return device.id ? device : undefined;
};
