import {
  AnyAction,
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { persistReducer, REHYDRATE } from "redux-persist";
import { RootState } from "store";
import { Platform } from "react-native";
import * as Application from "expo-application";
import uuid from "react-native-uuid";
import * as Localization from "expo-localization";
import { storage } from "utils/storage";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  type: REHYDRATE,
  effect: async (action: AnyAction, { dispatch, unsubscribe }) => {
    if (action.key !== "device") return;
    unsubscribe();

    const payload = action.payload as DeviceState | undefined;

    let id = payload?.id;

    if (!id) {
      if (Platform.OS === "ios")
        id = (await Application.getIosIdForVendorAsync()) || undefined;
      if (Platform.OS === "android")
        id = Application.getAndroidId() || undefined;

      id = id || (uuid.v4() as string);
      dispatch(updateDevice({ id }));
    }
  },
});

export type DeviceState = Readonly<{
  id: string;
  platform: string;
  pushToken?: string;
  timezone?: string;
  languageTags: string[];
  refDeviceId?: string;
  online: boolean;
}>;
const initialState: DeviceState = {
  id: undefined as unknown as string,
  platform: Platform.OS,
  timezone: Localization.getCalendars()?.[0].timeZone || undefined,
  languageTags: Localization.getLocales().map((_) => _.languageTag),
  online: false,
};

export const device = createSlice({
  name: "device",
  initialState,
  reducers: {
    updateDevice: (state, action: PayloadAction<Partial<DeviceState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { updateDevice } = device.actions;

export const selectDevice = (state: RootState) => state.device;
export const selectDeviceId = (state: RootState) => state.device.id;
export const selectOnline = (state: RootState) => state.device.online;

export default {
  reducer: persistReducer(
    {
      key: "device",
      storage,
    },
    device.reducer
  ),
  middleware: listenerMiddleware.middleware,
};
