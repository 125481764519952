import { router } from "expo-router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SectionListMenu,
  SectionListMenuItem,
  Switch,
} from "components/common";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useMutation } from "@apollo/client";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { Uploads } from "components/Uploads/Uploads";
import { useDraft } from "components/drafts/drafts";
import { MediaTypeOptions } from "expo-image-picker";
import { refetchQueries } from "utils/refetchQueries";
import { UploadFragment } from "components/Uploads/Upload";

const MediaAdCreate_Mutation = gql(`
  mutation MediaAdCreate_Mutation(
    $data: MediaAdCreateInput!
  ) {
    mediaAdCreate(data: $data) {
      id
    }
  }
`);

const MediaAdCreate_QueryFragment = gql(`
  fragment MediaAdCreate_QueryFragment on Query {
    org(id: $orgId) {
      id
      ...GetListItem_OrgFragment
    }
  }
`);

type InputErrors = {
  name?: string;
  upload?: string;
};

type CampaignReportAddProps = {
  query?: FragmentType<typeof MediaAdCreate_QueryFragment>;
};

export const MediaAdCreate = (props: CampaignReportAddProps) => {
  const query = getFragmentData(MediaAdCreate_QueryFragment, props.query);

  const { t: g } = useTranslation();
  const { t } = useTranslation("MediaAdCreate");

  const [draft, setDraft] = useDraft("mediaAdDraft");
  const [uploading, setUploading] = useState(false);

  const [createMediaAd, { loading }] = useMutation(MediaAdCreate_Mutation, {
    refetchQueries: refetchQueries.MediaAd,
    onCompleted() {
      setDraft();
      router.back();
    },
  });

  const nameRef = useRef<SectionListMenuItem>(null);
  const uploadRef = useRef<SectionListMenuItem>(null);
  const [errors, setErrors] = useState<InputErrors>({});
  useEffect(() => {
    errors.name && nameRef.current?.shake();
    errors.upload && uploadRef.current?.shake();
  }, [errors]);

  const submit = useCallback(() => {
    const errors: InputErrors = {};
    if (!draft?.name?.length) errors.name = t("name.empty");
    if (!draft?.upload?.id) errors.upload = t("upload.empty");

    setErrors(errors);

    if (Object.keys(errors).length) return;

    createMediaAd({
      variables: {
        data: {
          orgId: query?.org?.id || "",
          name: draft.name,
          uploadId: draft.upload?.id || "",
          enabled: draft.enabled,
        },
      },
    });
  }, [
    createMediaAd,
    draft.enabled,
    draft.name,
    draft.upload?.id,
    query?.org?.id,
    t,
  ]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "header",
        data: [
          getOrgListItem({
            key: "org",
            org: query?.org,
            loading: !query,
            picker: true,
            onOrgIdChange: (orgId) => {
              setDraft({ orgId });
            },
          }),
        ],
      },
      {
        key: "name",
        title: t("name.title"),
        error: errors.name,
        data: [
          {
            ref: nameRef,
            key: "name",
            type: "textInput",
            textInputProps: {
              value: draft.name || "",
              onChangeText: (name) => {
                setErrors({ ...errors, name: undefined });
                setDraft({ name });
              },
            },
          },
        ],
      },
      {
        key: "upload",
        title: t("upload.title"),
        error: errors.upload,
        data: [
          {
            ref: uploadRef,
            key: "upload",
            Component: (
              <Uploads
                editable
                uploads={draft.upload ? [draft.upload] : []}
                onChange={(uploads) => {
                  const upload = getFragmentData(UploadFragment, uploads[0]);
                  setDraft({
                    upload: {
                      ...uploads[0],
                      id: upload?.id,
                    },
                  });
                }}
                onUploadingStateChange={setUploading}
                options={{
                  multiple: false,
                  camera: false,
                  mediaLibrary: { mediaTypes: MediaTypeOptions.Videos },
                  doc: { type: "video/*" },
                }}
              />
            ),
          },
        ],
      },
      {
        key: "enabled",
        data: [
          {
            key: "enabled",
            title: t("enabled"),
            TrailingComponent: (
              <Switch
                value={draft.enabled || false}
                onValueChange={(enabled) => setDraft({ enabled })}
              />
            ),
          },
        ],
      },
      {
        key: "create",
        data: [
          {
            key: "create",
            title: uploading ? g("filesUploading") : g("create"),
            buttonType: "primary",
            centerContent: true,
            loading,
            onPress: submit,
            disabled: !query || !query.org || uploading,
          },
        ],
      },
    ];
  }, [
    query,
    t,
    errors,
    draft.name,
    draft.upload,
    uploading,
    g,
    loading,
    submit,
    setDraft,
  ]);

  return <SectionListMenu sections={sections} />;
};
