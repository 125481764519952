import { useQuery } from "@apollo/client";
import { OrgSettings } from "components/Org/OrgSettings";
import { Stack, useLocalSearchParams } from "expo-router";
import { Org_Query } from ".";
import { HeaderButton } from "components/common";
import { useTranslation } from "react-i18next";

export default function Page() {
  const { t: g } = useTranslation();
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Org_Query, { variables: { id } });

  return (
    <>
      <Stack.Screen
        options={{
          headerRight: () => <HeaderButton text={g("done")} bold />,
        }}
      />
      <OrgSettings query={data} />
    </>
  );
}
