import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Car } from "components/Car/Car";
import { useLocalSearchParams } from "expo-router";

export const Car_Query = gql(`
  query Car_Query($id: ObjectID!, $hideCurrentOrgMemberRole: Boolean = true) {
    ...Car_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(Car_Query, { variables: { id } });

  return <Car query={data} />;
}
