import { FragmentType, getFragmentData, gql } from "__generated__";
import { SectionListMenuItemProps, UserContent } from "components/common";
import { Link } from "expo-router";
import { getFixedT } from "i18next";

const GetListItem_MediaAdFragment = gql(`
  fragment GetListItem_MediaAdFragment on MediaAd {
    id
    name
    org {
      id
      name
    }
    enabled
    impressionsCount
    viewsCount
  }
`);

type GetAdListItemProps = {
  ad?: FragmentType<typeof GetListItem_MediaAdFragment> | null;
  showOrgName?: boolean;
  showCampaignName?: boolean;
};

export const getMediaAdListItem = (
  props?: GetAdListItemProps
): SectionListMenuItemProps => {
  const ad = getFragmentData(GetListItem_MediaAdFragment, props?.ad);
  const t = getFixedT(null, "getMediaAdListItem");

  return {
    key: ad?.id || "",
    Link: ad ? <Link href={`/mediaAd/${ad.id}/`} /> : undefined,
    Content: (
      <UserContent
        title={ad?.name || " "}
        subtitle={[
          ad?.org.name || " ",
          t("impressionsCount", { count: ad?.impressionsCount || 0 }),
          t("viewsCount", { count: ad?.viewsCount || 0 }),
        ]}
        loading={!ad}
      />
    ),
    loadingTrailing: !ad,
    shimmerTrailingMinWidth: 80,
    trailingText: ad?.enabled ? t("enabled") : undefined,
  };
};
