import { StyleSheet, Text, View } from "components/common";
import { Fragment } from "react";

export type ReportItem = {
  label: string;
  labelLoading?: boolean;
  text?: string | number;
  Component?: JSX.Element;
};

type ReportItemsProps = {
  items: ReportItem[];
  loading: boolean;
};
export const ReportItems = (props: ReportItemsProps) => {
  return (
    <View style={styles.container} colorName="card">
      {props.items.map((item, index) => (
        <Fragment key={item.label}>
          {!!index && <View style={styles.separator} colorName="border" />}
          <View style={styles.item}>
            <Text
              style={styles.label}
              numberOfLines={1}
              colorName="textSecondary"
              loading={item.labelLoading}
              shimmerMaxWidth={80}
            >
              {item.label}
            </Text>
            {item.Component ? (
              item.Component
            ) : (
              <Text
                style={styles.text}
                loading={!props.loading}
                numberOfLines={1}
                shimmerMaxWidth={100}
              >
                {item.text || "-"}
              </Text>
            )}
          </View>
        </Fragment>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
    maxWidth: 400,
    width: "100%",
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    marginStart: 16,
  },
  item: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    gap: 4,
  },
  label: {},
  text: {
    fontSize: 17,
  },
});
