import { gql } from "__generated__";

export const CarDraft_Query = gql(`
  query CarDraft_Query {
    carDraft @client {
      orgId
      plate
      brand
      model
      year
      driverIds
    }
  }
`);
