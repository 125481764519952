import "mapbox-gl/dist/mapbox-gl.css";
import "./DeviceMap.css";
import React, { useEffect, useMemo, useRef } from "react";
import Map, { Source, Layer, MapRef } from "react-map-gl";
import type { FeatureCollection } from "geojson";
import { Platform, StyleSheet, useColorScheme } from "components/common";
import { FragmentType, getFragmentData, gql } from "__generated__";
import config from "config";
import { useSelector } from "react-redux";
import { selectLang } from "store/settings";
import Theme from "constants/Theme";

const DeviceMap_QueryFragment = gql(`
  fragment DeviceMap_QueryFragment on Device {
    id
    location {
      coords {
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
      }
    }
  }
`);

type DeviceMapProps = {
  device?: FragmentType<typeof DeviceMap_QueryFragment> | null;
};

export const DeviceMap = (props: DeviceMapProps) => {
  const device = getFragmentData(DeviceMap_QueryFragment, props.device);

  const routeLine = useMemo(
    (): FeatureCollection => ({
      type: "FeatureCollection",
      features: device?.location
        ? [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  device?.location.coords.longitude,
                  device?.location.coords.latitude,
                  device?.location.coords.altitude,
                ],
              },
              properties: {
                accuracy: device?.location.coords.accuracy,
              },
            } as FeatureCollection["features"][0],
          ]
        : [],
    }),
    [device?.location]
  );

  console.log(routeLine);

  const lang = useSelector(selectLang);
  const scheme = useColorScheme();

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (!device?.location) return;

    mapRef.current?.flyTo({
      center: [
        device.location.coords.longitude,
        device.location.coords.latitude,
      ],
      zoom: 16,
    });
  }, [device?.location]);

  return (
    <Map
      ref={(ref) => {
        // @ts-ignore
        mapRef.current = ref;
        if (
          ref &&
          "setLanguage" in ref &&
          typeof ref.setLanguage === "function"
        )
          ref.setLanguage(lang);
      }}
      mapboxAccessToken={config.mapbox.accessToken}
      initialViewState={{
        bounds: [
          [27.385, 53.82],
          [27.72, 53.98],
        ],
      }}
      bearing={0.5}
      style={{
        ...styles.map,
        ...{ backgroundColor: scheme ? Theme[scheme].colors.card : undefined },
      }}
      mapStyle={
        scheme === "dark"
          ? "mapbox://styles/mapbox/dark-v11"
          : "mapbox://styles/mapbox/light-v11"
      }
      locale={{
        name: "ru",
      }}
    >
      <Source id="device" type="geojson" data={routeLine}>
        <Layer
          id="device-point"
          type="circle"
          paint={{
            "circle-radius": 5,
            "circle-color": "green",
            "circle-stroke-color": "white",
            "circle-stroke-opacity": 1,
            "circle-stroke-width": 2,
          }}
        />
        <Layer
          id="device-accuracy"
          type="circle"
          paint={{
            "circle-radius": {
              stops: [
                [0, 0],
                [20, device?.location?.coords?.accuracy || 0],
              ],
            },
            "circle-color": "blue",
            "circle-opacity": 0.1,
          }}
        />
      </Source>
    </Map>
  );
};

export interface MapboxDirections {
  code: string;
  uuid: string;
  waypoints: {
    distance: number;
    name: string;
    location: [number, number];
  }[];
  routes: {
    distance: number;
    duration: number;
    geometry: string;
    legs: {
      via_waypoints: [];
      admins: {
        iso_3166_1: string;
        iso_3166_1_alpha3: string;
      }[];
      distance: number;
      duration: number;
      steps: [];
      summary: string;
      weight: number;
    }[];
    weight: number;
    weight_name: string;
  }[];
}

const styles = StyleSheet.create({
  map: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
    marginTop: 16,

    ...Platform.select({
      default: {
        shadowColor: "rgba(0, 0, 0, 0.1)",
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 1,
        shadowRadius: 5,
        elevation: 5,
      },
      web: { boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" },
    }),
  },
});
