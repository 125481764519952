import { gql } from "__generated__";

export const CampaignDraft_Query = gql(`
  query CampaignDraft_Query {
    campaignDraft @client {
      orgId
      name
      periodDays
      carsCount
      regions
      comment
      uploads {
        ...UploadFragment
      }
    }
  }
`);
