import { useTranslation } from "react-i18next";
import {
  BlurView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useColors,
} from "../common";
import Icon from "assets/icons/icon.svg";
import Head from "expo-router/head";
import { Link } from "expo-router";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { ReportMap } from "./ReportMap";
import { ReportItem, ReportItems } from "./ReportItems";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { usePDF } from "utils/react-to-pdf";
import Animated, { SlideInUp, SlideOutUp } from "react-native-reanimated";
import PdfIcon from "assets/icons/pdf.svg";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Uploads } from "components/Uploads/Uploads";

const Report_QueryFragment = gql(`
  fragment Report_QueryFragment on Query {
    campaign(id: $id) {
        id
        name
        periodDays
        carsCount
        regions
        status
        plannedAt
        startedAt
        org {
            id
            name
        }
        ads {
            id
            createdAt
            car {
                id
                plate
                brand
                model
                coordinates
            }
        }
        ...ReportMap_QueryFragment
    }
  }
`);

type ReportProps = {
  query?: FragmentType<typeof Report_QueryFragment>;
};

export const Report = (props: ReportProps) => {
  const query = getFragmentData(Report_QueryFragment, props.query);

  const { t } = useTranslation("Report");

  const items = useMemo(() => {
    const items: [ReportItem[], ReportItem[]] = [[], []];

    const plannedAt = query?.campaign?.plannedAt
      ? moment(query.campaign.plannedAt)
      : null;
    const plannedFinishAt = query?.campaign?.plannedAt
      ? moment(query.campaign.plannedAt).add(
          query?.campaign?.periodDays,
          "days"
        )
      : null;

    const startedAt = query?.campaign?.startedAt
      ? moment(query.campaign.startedAt)
      : null;
    const finishAt = query?.campaign?.startedAt
      ? moment(query.campaign.startedAt).add(
          query?.campaign?.periodDays,
          "days"
        )
      : null;

    items[0].push({
      label: t("org"),
      text: query?.campaign?.org.name,
    });

    items[0].push({
      label: t("campaign"),
      text: query?.campaign?.name,
    });
    items[0].push({
      label: t("regions"),
      text: query?.campaign?.regions.join(", "),
    });
    items[0].push({
      label: t("photos"),
      Component: <Uploads loading={!query} />,
    });

    items[1].push({
      label: t("carsCount"),
      text: query?.campaign?.carsCount,
    });
    items[1].push({
      label: t("duration"),
      text: t("day", { count: query?.campaign?.periodDays }),
    });
    items[1].push({
      label: t("status"),
      text: query?.campaign ? t(`statusValue.${query.campaign.status}`) : "",
    });
    !startedAt &&
      items[1].push({
        label: t("plannedStart") + " " + plannedAt?.fromNow(),
        labelLoading: !query,
        text: plannedAt ? plannedAt.format("ll") : "",
      });
    !finishAt &&
      items[1].push({
        label: t("plannedFinish") + " " + plannedFinishAt?.fromNow(),
        labelLoading: !query,
        text: plannedFinishAt ? plannedFinishAt.format("ll") : "",
      });
    startedAt &&
      items[1].push({
        label: t("started") + " " + startedAt.fromNow(),
        text: startedAt ? startedAt.format("lll") : "",
      });
    finishAt &&
      items[1].push({
        label: t("finish") + " " + finishAt.fromNow(),
        text: finishAt ? finishAt.format("lll") : "",
      });

    return items;
  }, [query, t]);

  const count = useMemo(() => {
    return {
      active: query?.campaign?.ads.filter((_) => _.car.coordinates).length,
      inactive: query?.campaign?.ads.filter((_) => !_.car.coordinates).length,
    };
  }, [query?.campaign?.ads]);

  const [exporting, setExporting] = useState(false);
  const colors = useColors();
  const { toPDF, targetRef } = usePDF({
    filename: "page.pdf",
    background: colors.background,
  });

  useEffect(() => {
    exporting &&
      toPDF().finally(() => {
        setExporting(false);
      });
  }, [exporting, toPDF]);

  return (
    <ScrollView>
      <Head>
        <title>
          {query?.campaign ? `${query?.campaign?.name} - ` : ""}
          {t("title")}
        </title>
      </Head>
      {exporting && (
        <Animated.View
          style={styles.exporting}
          entering={SlideInUp}
          exiting={SlideOutUp}
        >
          <View
            style={[
              styles.exportingContainer,
              { backgroundColor: colors.primary + "20" },
            ]}
          >
            <Text style={styles.exportingText} colorName="primary">
              {t("exporting")}
            </Text>
          </View>
        </Animated.View>
      )}
      <View
        style={[
          styles.container,
          exporting && { maxWidth: "auto", width: 800 },
        ]}
        ref={targetRef}
        colorName="background"
      >
        <View style={styles.header}>
          <Link href="/">
            <Icon width={36} height={36} />
          </Link>
          <Text style={styles.title}>{t("title")}</Text>
          {!exporting && query && (
            <View
              style={{
                position: "absolute",
                right: 16,
                top: 0,
                width: 36,
                height: 36,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setExporting(true);
                }}
              >
                <PdfIcon width={36} height={36} />
              </TouchableOpacity>
            </View>
          )}
        </View>
        <View style={styles.infoWrap}>
          <ReportItems items={items[0]} loading={!!query} />
          <ReportItems items={items[1]} loading={!!query} />
        </View>
        {!exporting && (
          <View>
            <ReportMap campaign={query?.campaign} />
            <View style={styles.legend}>
              {!!count.active && (
                <BlurView style={styles.legendItem}>
                  <View style={styles.legendDot} />
                  <Text style={styles.legendItemText}>
                    {t("map.active")} ({count.active})
                  </Text>
                </BlurView>
              )}
              {!!count.inactive && (
                <BlurView style={styles.legendItem}>
                  <View
                    style={[styles.legendDot, { backgroundColor: "gray" }]}
                  />
                  <Text style={styles.legendItemText}>
                    {t("map.inactive")} ({count.inactive})
                  </Text>
                </BlurView>
              )}
            </View>
          </View>
        )}
        <View style={styles.ads}>
          <View style={styles.adsHeader}>
            <Text style={styles.title}>{t("photos")}</Text>
            <Text style={styles.adsCount} colorName="textSecondary">
              {query?.campaign?.ads.length}
            </Text>
          </View>
          {query?.campaign?.ads.map((ad) => {
            return (
              <View key={ad.id} style={styles.ad} colorName="card">
                <View style={styles.adHeader}>
                  <Text style={styles.adCarPlate}>{ad.car.plate}</Text>
                  <Text style={styles.adCarName} colorName="textSecondary">
                    {ad.car.brand} {ad.car.model}
                  </Text>
                  <Text style={styles.adCarCreated} colorName="textSecondary">
                    {t("createdAt", {
                      date: moment(ad.createdAt).format("lll"),
                    })}
                  </Text>
                </View>
                <View style={styles.adImages}>
                  {/* {ad.attachments.map((asset, index) => {
                    const uri = index
                      ? cloudinary
                          .image(asset?.public_id)
                          .resize(Resize.auto(800, 800))
                          .toURL()
                      : cloudinary
                          .image(asset?.public_id)
                          .resize(Resize.auto(800, 800).gravity(autoGravity()))
                          .toURL();

                    return (
                      <Image
                        key={asset.public_id}
                        style={styles.adImage}
                        source={{
                          thumbhash: asset.thumbhash,
                          uri,
                        }}
                      />
                    );
                  })} */}
                </View>
              </View>
            );
          })}
        </View>
        {query && (
          <Text style={styles.generated} colorName="textSecondary">
            {t("generated", { date: moment().format("lll") })}
          </Text>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    paddingVertical: 32,
    gap: 16,
    maxWidth: 800,
    width: "100%",
    alignSelf: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 22,
    marginTop: -6,
  },
  infoWrap: {
    flexDirection: "row",
    width: "100%",
    gap: 16,
    alignItems: "flex-start",
  },
  ads: {
    marginTop: 16,
    gap: 16,
  },
  adsHeader: {
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
    paddingHorizontal: 16,
  },
  adsCount: {
    marginTop: -2,
  },
  ad: {
    borderRadius: 10,
    overflow: "hidden",
    padding: 16,
    gap: 16,
  },
  adHeader: {
    flexDirection: "row",
    gap: 16,
  },
  adCarPlate: {
    fontSize: 17,
  },
  adCarName: {
    fontSize: 15,
  },
  adCarCreated: {
    flex: 1,
    textAlign: "right",
  },
  adImages: {
    flexDirection: "row",
    gap: 8,
  },
  adImage: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
  },

  legend: {
    position: "absolute",
    bottom: 16,
    left: 16,
    flexDirection: "row",
    gap: 8,
  },
  legendItem: {
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  legendDot: {
    backgroundColor: "green",
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
  },
  legendItemText: {
    fontSize: 12,
    fontWeight: "500",
  },
  exporting: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
    pointerEvents: "none",
  },
  exportingContainer: {
    backgroundColor: "red",
    alignSelf: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  exportingText: {
    fontSize: 17,
    fontWeight: "500",
  },
  generated: {
    textAlign: "center",
    fontSize: 12,
  },
});
