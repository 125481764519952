import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { App } from "components/App/App";
import { useLocalSearchParams } from "expo-router";

const App_Query = gql(`
  query App_Query($id: ObjectID!) {
    ...App_QueryFragment
  }
`);

export default function Page() {
  const { id = "" } = useLocalSearchParams<{ id: string }>();
  const { data } = useQuery(App_Query, { variables: { id } });

  return <App query={data} />;
}
