import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { CampaignCreate } from "components/Campaign/CampaignCreate";
import { useDraft } from "components/drafts/drafts";
import { useLocalSearchParams } from "expo-router";
import { useEffect } from "react";

export const CampaignCreate_Query = gql(`
  query CampaignCreate_Query($orgId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {
    ...CampaignCreate_QueryFragment
  }
`);

export default function Page() {
  const { orgId } = useLocalSearchParams<{ orgId?: string }>();
  const [draft, setDraft] = useDraft("campaignDraft");

  useEffect(() => {
    orgId && setDraft({ orgId });
  }, [orgId, setDraft]);

  const { data } = useQuery(CampaignCreate_Query, {
    variables: {
      orgId: draft.orgId,
    },
  });
  return <CampaignCreate query={data} />;
}
