import { forwardRef, useEffect, useRef, useState } from "react";
import {
  Platform,
  StyleSheet,
  TextInputProps as _TextInputProps,
  TextInput as _TextInput,
} from "react-native";
import { useColors } from "./useColors";
import Animated, {
  WithTimingConfig,
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import * as Haptics from "expo-haptics";
import "./TextInput.css";
import { ActivityIndicator } from "./ActivityIndicator";

const timingConfig: WithTimingConfig = {
  duration: 200,
};

const errorColor = "#e53935FF";
export type TextInput = _TextInput;
export type TextInputProps = _TextInputProps & {
  error?: string;
  loading?: boolean;
  borderColor?: string;
  primaryColor?: string;
  backgroundColor?: string;
  placeholderColor?: string;
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextInput = forwardRef<TextInput, TextInputProps>(
  function RectButton(props, ref) {
    const {
      style,
      placeholder,
      error,
      loading,
      borderColor,
      primaryColor,
      backgroundColor,
      placeholderColor,
      ...rest
    } = props;

    const colors = useColors();
    const [focused, setFocused] = useState(false);

    const active = !!(focused || rest.value?.length || error);

    const animatedBorderStyle = useAnimatedStyle(() => {
      return {
        borderColor: withTiming(
          error
            ? errorColor
            : focused
            ? primaryColor || colors.primary
            : borderColor || colors.border,
          timingConfig
        ),
        borderWidth: error ? 2 : withTiming(focused ? 2 : 1, timingConfig),
      };
    }, [focused, error, colors, borderColor, primaryColor]);

    const animatedPlaceholderStyle = useAnimatedStyle(() => {
      return {
        top: withTiming(active ? -8 : 14, timingConfig),
      };
    }, [active]);

    const animatedPlaceholderTextStyle = useAnimatedStyle(() => {
      return {
        color: error
          ? errorColor
          : withTiming(
              focused
                ? primaryColor || colors.primary
                : placeholderColor || colors.textSecondary,
              timingConfig
            ),
        fontSize: withTiming(active ? 13 : 16, timingConfig),
        paddingLeft: withTiming(active ? 4 : 12, timingConfig),
        paddingRight: withTiming(active ? 4 : 12, timingConfig),
      };
    }, [focused, active, error, colors, primaryColor, placeholderColor]);

    const inputRef = useRef<TextInput>(null);

    const offset = useSharedValue(0);
    useEffect(() => {
      if (!error?.length) return;

      offset.value = withSequence(
        withTiming(2, { duration: 75 }),
        withTiming(-2, { duration: 75 }),
        withTiming(2, { duration: 75 }),
        withSpring(0, { damping: 50, stiffness: 500, mass: 2.8 })
      );

      Platform.OS !== "web" &&
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    }, [error?.length, offset]);

    const animatedStyle = useAnimatedStyle(() => {
      return {
        transform: [{ translateX: offset.value }],
      };
    });

    return (
      <Animated.View style={[styles.container, animatedStyle]}>
        <_TextInput
          ref={(node) => {
            // @ts-expect-error manual ref
            inputRef.current = node;
            if (typeof ref === "function") ref(node);
            else if (ref) ref.current = node;
          }}
          style={[
            styles.input,
            { color: error ? errorColor : colors.text },
            style,
          ]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          cursorColor={primaryColor || colors.primary}
          // @ts-expect-error for css
          dataSet={!error && { input: "1" }}
          aria-label={placeholder}
          {...rest}
        />
        <Animated.View style={[styles.border, animatedBorderStyle]} />
        <Animated.View
          style={[styles.placeholderContainer, animatedPlaceholderStyle]}
        >
          <Animated.Text
            style={[
              styles.placeholder,
              { backgroundColor: backgroundColor || colors.backgroundPrimary },
              animatedPlaceholderTextStyle,
            ]}
            numberOfLines={1}
          >
            {error || placeholder}
          </Animated.Text>
        </Animated.View>
        {loading && <ActivityIndicator style={styles.loading} />}
      </Animated.View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
  },
  border: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8,
    borderWidth: 1,
    pointerEvents: "none",
  },
  input: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 14,
    paddingRight: 48,
    fontSize: 16,
  },
  placeholderContainer: {
    pointerEvents: "none",
    position: "absolute",
    left: 4,
    right: 4,
    alignItems: "flex-start",
  },
  placeholder: {
    fontSize: 16,
    borderRadius: 3,
  },
  loading: {
    position: "absolute",
    right: 16,
    pointerEvents: "none",
  },
});
