import { useSubscription } from "@apollo/client";
import { gql } from "__generated__";
import { useSelector } from "react-redux";
import { selectOnline } from "store/device";

const Online_Subscription = gql(`
  subscription Online_Subscription {
    online
  }
`);

export const useOnline = () => {
  useSubscription(Online_Subscription);

  const online = useSelector(selectOnline);

  return online;
};
