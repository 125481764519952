import { StyleSheet, View } from "components/common";
import { Upload, UploadFragment } from "./Upload";
import { FragmentType, getFragmentData } from "__generated__";
import { UploadCreate } from "./UploadCreate";
import { UseAssetPickerProps } from "hooks/useAssetPicker";
import { useEffect } from "react";

type UploadsProps = {
  uploads?: FragmentType<typeof UploadFragment>[];
  onChange?: (uploads: FragmentType<typeof UploadFragment>[]) => void;
  onUploadingStateChange?: (uploading: boolean) => void;
  loading?: boolean;
  editable?: boolean;
  options?: UseAssetPickerProps;
  selectionLimit?: number;
  type?: string;
};

export const Uploads = (props: UploadsProps) => {
  const selectionLimit =
    props.options?.multiple === false ? 1 : props.selectionLimit;

  useEffect(() => {
    const uploading = props.uploads?.some((uploadFragment) => {
      const upload = getFragmentData(UploadFragment, uploadFragment);
      return !!upload?.progress;
    });
    props.onUploadingStateChange?.(uploading || false);
  }, [props, props.uploads]);

  return (
    <View style={styles.container}>
      {(props.editable || props.loading) &&
        selectionLimit !== props.uploads?.length && (
          <UploadCreate
            loading={props.loading}
            options={props.options}
            selectionLimit={selectionLimit}
            onUploadsCreate={(uploads) => {
              props.onChange?.([...uploads, ...(props.uploads || [])]);
            }}
          />
        )}
      {props.uploads?.map((upload) => {
        const key = getFragmentData(UploadFragment, upload)?.id || "upload";
        return (
          <Upload
            key={key}
            upload={upload}
            editable={props.editable}
            onRemove={() => {
              props.onChange?.(
                props.uploads?.filter((u) => u !== upload) || []
              );
            }}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -4,
  },
});
