import { router } from "expo-router";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionListMenu } from "components/common";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useMutation } from "@apollo/client";
import {
  Ads_QueryDocument,
  ControlAds_QueryDocument,
} from "__generated__/graphql";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getCampaignListItem } from "components/Campaign/getCampaignListItem";
import { getCarListItem } from "components/Car/getCarListItem";
import { Uploads } from "components/Uploads/Uploads";
import { useDraft } from "components/drafts/drafts";

const AddCreate_Mutation = gql(`
  mutation AddCreate_Mutation(
    $campaignId: ObjectID!
    $data: AdCreateInput!
  ) {
    addAd(campaignId: $campaignId, data: $data) {
      id
    }
  }
`);

const AdCreate_QueryFragment = gql(`
  fragment AdCreate_QueryFragment on Query {
    campaign(id: $campaignId) {
      id
      ...GetListItem_CampaignFragment
    }
    org(id: $orgId) {
      id
      ...GetListItem_OrgFragment
    }
    car(id: $carId, orgId: $orgId) {
      id
      ...GetListItem_CarFragment
    }
  }
`);

type CampaignReportAddProps = {
  query?: FragmentType<typeof AdCreate_QueryFragment>;
};

export const AdCreate = (props: CampaignReportAddProps) => {
  const query = getFragmentData(AdCreate_QueryFragment, props.query);

  const { t: g } = useTranslation();
  const { t } = useTranslation("AdCreate");

  const [draft, setDraft] = useDraft("adDraft");
  const [uploading, setUploading] = useState(false);

  const [createAd, { loading }] = useMutation(AddCreate_Mutation, {
    refetchQueries: [ControlAds_QueryDocument, Ads_QueryDocument],
    onCompleted() {
      setDraft();
      router.back();
    },
  });
  const submit = useCallback(() => {
    query?.campaign &&
      createAd({
        variables: {
          campaignId: query?.campaign.id,
          data: {
            carId: draft.carId!,
          },
        },
      });
  }, [createAd, draft.carId, query?.campaign]);

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "campaign",
        data: [
          getCampaignListItem({
            key: "campaign",
            campaign: query?.campaign,
            loading: !query,
            picker: true,
            onCampaignIdChange: (campaignId: string) => {
              setDraft({ campaignId });
            },
          }),
        ],
      },
      {
        key: "header",
        title: t("placement"),
        data: [
          getOrgListItem({
            key: "org",
            org: query?.org,
            loading: !query,
            picker: true,
            onOrgIdChange: (orgId) => {
              setDraft({ orgId });
            },
          }),
          getCarListItem({
            car: query?.car,
            loading: !query,
            picker: true,
            onCarIdChange: (carId: string) => {
              setDraft({ carId });
            },
          }),
        ],
      },
      {
        key: "photos",
        title: t("photos.title"),
        data: [
          {
            key: "photos",
            Component: (
              <Uploads
                editable
                uploads={draft.upload ? [draft.upload] : []}
                onChange={(uploads) => {
                  setDraft({
                    upload: uploads[0],
                  });
                }}
                onUploadingStateChange={setUploading}
              />
            ),
          },
        ],
      },
      {
        key: "create",
        data: [
          {
            key: "create",
            title: uploading ? g("filesUploading") : t("create"),
            buttonType: "primary",
            centerContent: true,
            loading,
            onPress: submit,
            disabled:
              !query ||
              !query.org ||
              !query.campaign ||
              !query.car ||
              uploading,
          },
        ],
      },
    ];
  }, [query, t, draft.upload, uploading, g, loading, submit, setDraft]);

  return <SectionListMenu sections={sections} />;
};
