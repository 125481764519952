import {
  DimensionValue,
  Platform,
  StyleSheet,
  View,
  Text as _Text,
} from "react-native";
import { ThemeProps } from "./types";
import { useState } from "react";
import * as Haptics from "expo-haptics";
import { useColors } from "./useColors";
import { useIsRTL } from "hooks/useIsRTL";
import { Shimmer } from "../Shimmer";
import { ActivityIndicator } from "./ActivityIndicator";
import { ExpoRouter } from ".expo/types/router";

export type TextProps = ThemeProps &
  _Text["props"] & {
    link?: ExpoRouter.LinkProps["href"];
    linkAction?: "push" | "replace" | "back";
    loading?: boolean;
    loadingIndicatorType?: "shimmer" | "activityIndicator";
    shimmerMinWidth?: DimensionValue;
    shimmerMaxWidth?: DimensionValue;
  };
export function Text(props: TextProps) {
  const {
    style,
    colorName = "text",
    link,
    linkAction = "push",
    onPress,
    loading,
    loadingIndicatorType = "shimmer",
    shimmerMinWidth,
    shimmerMaxWidth,
    children,
    ...rest
  } = props;
  const colors = useColors();
  const color = props.color || colors[colorName];
  const isRTL = useIsRTL();

  const isLink = !!(onPress || link || linkAction === "back");
  const [isActive, setIsActive] = useState(false);

  if (loading) {
    const textStyle = StyleSheet.flatten(style) || {};
    const alignItems =
      textStyle.textAlign === "center"
        ? "center"
        : textStyle.textAlign === "right"
        ? "flex-end"
        : "flex-start";

    return (
      <View
        style={[
          styles.shimmerContainer,
          textStyle,
          { alignItems, minWidth: shimmerMinWidth },
        ]}
      >
        <_Text style={style}> </_Text>
        {loadingIndicatorType === "activityIndicator" ? (
          <ActivityIndicator style={StyleSheet.absoluteFill} />
        ) : (
          <Shimmer
            style={[
              styles.shimmer,
              { minWidth: shimmerMinWidth, maxWidth: shimmerMaxWidth },
            ]}
          />
        )}
      </View>
    );
  }

  return (
    <_Text
      style={[
        { color, textAlign: "left" },
        isLink && {
          color: colors.primary,
        },
        isActive && { backgroundColor: "#0002" },
        Platform.OS === "web" && { textAlign: isRTL ? "right" : "left" },
        style,
      ]}
      onPressIn={
        isLink
          ? () => {
              if (Platform.OS === "ios")
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
              else setIsActive(true);
            }
          : undefined
      }
      onPressOut={
        isLink
          ? () => {
              if (Platform.OS === "ios")
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
              else setIsActive(false);
            }
          : undefined
      }
      onPress={isLink ? onPress : undefined}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dir={isRTL ? "rtl" : "ltr"}
      {...rest}
    >
      {children !== undefined ? children : " "}
    </_Text>
  );
}

const styles = StyleSheet.create({
  shimmerContainer: {
    minWidth: 16,
  },
  shimmer: {
    position: "absolute",
    top: 2,
    bottom: 2,
    width: "100%",
    borderRadius: 3,
  },
});
