import { gql } from "__generated__";

export const AdDraft_Query = gql(`
  query AdDraft_Query {
    adDraft @client {
      orgId
      campaignId
      carId
      upload {
        ...UploadFragment
      }
    }
  }
`);
