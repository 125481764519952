import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { AdCreate } from "components/Ad/AdCreate";
import { useDraft } from "components/drafts/drafts";
import { useLocalSearchParams } from "expo-router";
import { useEffect } from "react";

export const AdCreate_Query = gql(`
  query AdCreate_Query($orgId: ObjectID, $campaignId: ObjectID, $carId: ObjectID, $hideCurrentOrgMemberRole: Boolean = true) {
    ...AdCreate_QueryFragment
  }
`);

export default function Page() {
  const [draft, setDraft] = useDraft("adDraft");
  const { carId } = useLocalSearchParams<{ carId?: string }>();

  useEffect(() => {
    carId && setDraft({ carId });
  }, [carId, setDraft]);

  const { data } = useQuery(AdCreate_Query, {
    variables: {
      orgId: draft.orgId,
      campaignId: draft.campaignId,
      carId: draft.carId,
    },
  });

  return <AdCreate query={data} />;
}
