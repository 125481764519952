import { useQuery } from "@apollo/client";
import { CampaignDraft_Query } from "./campaignDraft";
import {
  AdDraft_QueryQuery,
  AppDraft_QueryQuery,
  CampaignDraft_QueryQuery,
  CarDraft_QueryQuery,
  MediaAdDraft_QueryQuery,
} from "__generated__/graphql";
import { useCallback, useMemo } from "react";
import { cache } from "utils/appolo";
import { AppDraft_Query } from "./appDraft";
import { AdDraft_Query } from "./adDraft";
import { CarDraft_Query } from "./carDraft";
import { MediaAdDraft_Query } from "./mediaAdDraft";

const types = {
  mediaAdDraft: MediaAdDraft_Query,
  adDraft: AdDraft_Query,
  appDraft: AppDraft_Query,
  carDraft: CarDraft_Query,
  campaignDraft: CampaignDraft_Query,
};

const initialValues: InitialValues = {
  mediaAdDraft: {
    name: "",
    orgId: null,
    upload: null,
    enabled: false,
  },
  adDraft: {
    orgId: null,
    campaignId: null,
    carId: null,
    upload: null,
  },
  appDraft: {
    upload: null,
    active: false,
  },
  carDraft: {
    orgId: null,
    plate: "",
    brand: "",
    model: "",
    year: "",
    driverIds: [],
  },
  campaignDraft: {
    orgId: null,
    carsCount: "",
    comment: "",
    name: "",
    periodDays: "",
    regions: [],
    uploads: [],
  },
};

type Types = keyof typeof types;
type InitialValues = {
  mediaAdDraft: MediaAdDraft_QueryQuery["mediaAdDraft"];
  adDraft: AdDraft_QueryQuery["adDraft"];
  appDraft: AppDraft_QueryQuery["appDraft"];
  carDraft: CarDraft_QueryQuery["carDraft"];
  campaignDraft: CampaignDraft_QueryQuery["campaignDraft"];
};

export const useDraft = <T extends Types>(type: T) => {
  const query = types[type];
  const initialData = initialValues[type];

  const { data } = useQuery(query);

  const _data = useMemo(() => {
    const d = Object.values(data || {})[0];
    return typeof d === "object" ? d : {};
  }, [data]);

  const setDraft = useCallback(
    (draft?: Partial<typeof initialData>) => {
      cache.writeQuery({
        query,
        data: {
          [type]: draft
            ? {
                ...initialData,
                ..._data,
                ...draft,
              }
            : initialData,
        },
      });
    },
    [_data, initialData, query, type]
  );

  return [{ ...initialData, ..._data }, setDraft] as const;
};
