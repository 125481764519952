import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SectionListMenu } from "components/common";
import { FragmentType, gql, getFragmentData } from "__generated__";
import { getOrgListItem } from "components/Org/getOrgListItem";
import { getCampaignListItem } from "components/Campaign/getCampaignListItem";
import { getCarListItem } from "components/Car/getCarListItem";
import moment from "moment";
import { Uploads } from "components/Uploads/Uploads";

const Ad_QueryFragment = gql(`
  fragment Ad_QueryFragment on Query {
    ad(id: $id) {
      id
      createdAt
      campaign {
        id
        periodDays
        ...GetListItem_CampaignFragment
        org {
          ...GetListItem_OrgFragment
        }
      }
      car {
        ...GetListItem_CarFragment
      }
    }
  }
`);

type CampaignReportProps = {
  query?: FragmentType<typeof Ad_QueryFragment>;
};

export const Ad = (props: CampaignReportProps) => {
  const query = getFragmentData(Ad_QueryFragment, props.query);

  const { t } = useTranslation("Ad");

  const sections = useMemo((): SectionListMenu[] => {
    return [
      {
        key: "car",
        data: [
          getOrgListItem({ org: query?.ad?.campaign.org }),
          getCampaignListItem({ campaign: query?.ad?.campaign }),
          getCarListItem({ car: query?.ad?.car }),
        ],
      },
      {
        key: "status",
        data: [
          {
            key: "createdAt",
            label: t("createdAt"),
            loading: !query,
            title: moment(query?.ad?.createdAt).format("lll"),
          },
          {
            key: "expireAt",
            label: t("expireAt"),
            loading: !query,
            title:
              moment(query?.ad?.createdAt)
                .add(query?.ad?.campaign.periodDays, "days")
                .format("lll") +
              ` (${moment(query?.ad?.createdAt)
                .add(query?.ad?.campaign.periodDays, "days")
                .fromNow()})`,
          },
        ],
      },
      {
        key: "photos",
        title: t("photos"),
        data: [
          {
            key: "photos",
            Component: <Uploads loading={!query} />,
          },
        ],
      },
    ];
  }, [query, t]);

  return <SectionListMenu sections={sections} />;
};
